import React from 'react'
import { Icon, Button, Spin, Breadcrumb, Row,Col, Table, Divider, Popconfirm, Form, Input,message } from 'antd';
import App from '../App'
const FormItem = Form.Item
class CreateProperty extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    render(){
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return(
            <App header="Create Property">
                <Form onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Hotel Name">
                                {getFieldDecorator(`hotelName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Hotel Name",
                                    },
                                ]
                                })(
                                    <Input placeholder="Hotel Name"  style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                </Row>
                </Form>
            </App>
        )
    }
}
const WrappedCreateProperty = Form.create()(CreateProperty);
export default WrappedCreateProperty