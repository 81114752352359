import React from 'react'
import { Link } from 'react-router-dom';
import { Icon, Button, Spin, Breadcrumb, Row,Col, Select, DatePicker, InputNumber, Form, Input,message } from 'antd';
import { compose, graphql, withApollo } from 'react-apollo'
import moment from 'moment';
import createBulkSlot from '../mutation/createBulkSlot'
import App from '../App'
var inputArr=[];


var toInt  = time => ((h,m) => h * 1 + m)(...time.split(':').map(parseFloat)),
    toTime = int => [Math.floor(int/1), int%1 ? '30' : '00'].join(':'),
    range  = (from, to) => 
    Array(to-from+1)
    .fill()
    .map((_,i) => from + i),
    eachHalfHour = (t1, t2) => range(...[t1, t2].map(toInt)).map(toTime);
const { Option } = Select;
const { RangePicker } = DatePicker ;
const FormItem = Form.Item
const dateFormat = 'DD/MM/YYYY';


class CreateBulkSlot extends React.Component{
    constructor(props){
        super(props)
        this.state={
            disable: false,
            selected:[],
            time: [] ,
            date:null,
            epochDate:'',
            arr:[]
            
        }
    }
    onChangedate = (value,value1)=> {
        console.log(`selected ${value}`);
        var aa = value1[0];
        var bb = value1[1];
        console.log("startd",aa)
        console.log("endDate",bb)
        aa = aa.toString().split('/')
        bb = bb.toString().split('/')
        console.log("startd 11",aa)
        console.log("endDate",bb)
        // aa=aa[0]+'/'+aa[1]+'/'+aa[2];
        // bb = bb[0]+'/'+bb[1]+'/'+bb[2];
        var date1 = new Date(aa[2],Number(aa[1])-1,aa[0]); 
        var date2 = new Date(bb[2],Number(bb[1])-1,bb[0]); 
        var date3 = new Date(2019,12,16);
        console.log("startd",aa)
        console.log("endDate",bb)
          console.log("date1",date1,date2,date3)
         
        // To calculate the time difference of two dates 
        var Difference_In_Time = date2.getTime() - date1.getTime(); 
          
        // To calculate the no. of days between two dates 
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
          console.log("diffre",Difference_In_Days)
        var loopDate = 0;
        var new_date = moment(date1, 'DD-MM-YYYY').add(
          'days',
          1,
        );
        // console.log("add dates",new_date);
        var day = new_date.format('DD');
      var month = new_date.format('MM');
      var year = new_date.format('YYYY');
      // console.log('In if of query ::', new_date, day, month, year);
      
      var input=null;
      // console.log('roomid',)
      var currentDate;
      // console.log("epoch date",currentDate,new Date(year,month,day));
        for(loopDate = 0; loopDate <= Difference_In_Days; loopDate++)
        {
        console.log("loopdate",loopDate,date1)
          new_date = moment(date1, 'DD-MM-YYYY').add(
            'days',
            loopDate,
          );
          // console.log("add dates",new_date);
          day = new_date.format('DD');
          month = new_date.format('MM');
          year = new_date.format('YYYY');

          console.log('In if of query ::', new_date, day, month, year);
          currentDate = Math.floor(new Date(year,month,day) / 1000);
          input={
            roomId:this.props.location.state.roomId,
            "slotDate":day+'-'+ month+'-'+ year,
            epochDate:currentDate,
            // slotsList:this.state.arr,
          };
          inputArr.push(input);
        }
        console.log("final data",inputArr)
        this.setState({
          date:aa,
        });
        // this.setState({
        //   startd:aa,
        //   endDate:bb
        // });
         
      }
    handleclick = (i) => {
        console.log('clicked',i);
        let arr =this.state.selected
        arr.push(i) 
        this.setState({selected:arr, disable: true})
        
      }
      handleclick1 =()=>{
        this.props.history.push('/roomslist')
    }
    onChange = (value) => {
        console.log(`selected ${value}`);
      }
    onSearch = (val) => {
        console.log('search:', val);
      }
      handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
                  this.setState({
                    arr:values.slots
                  });

            console.log("form data :: ", values)
          if (!err) {
            let finalArr = values.slots

            this.state.selected.map( i => finalArr[i].available = false)
            inputArr.map(val => val.slotsList = finalArr) ;
            console.log("final data",inputArr)
            console.log("FinalArr :: ", finalArr)
            console.log("jdgs",values.slots)
            this.props.client.mutate({
                mutation: createBulkSlot,
                variables: {
                  input: inputArr
                    }
               
            }).then((data) => {
                message.success('Slot Created sucessfully');
                console.log("success",data)
          
                })

            // message.success('Slot Created sucessfully');
            
          }
        });
      };
    render(){
        console.log("room data",this.props.location.state)
        console.log("Slots ::", this.state.time )
        var s = this.state.time
        const { getFieldDecorator, getFieldValue,} = this.props.form;
        var dtime = this.state.selected
        // var date1=this.props.date1
        // var dtime = this.state.selected
        // console.log("time",this.state.selected);
      var hourIntervals = eachHalfHour('00:00','23:00');
      var bbb = hourIntervals
      
      getFieldDecorator('keys', { initialValue: [] });
       const keys = getFieldValue('keys');
       const varhour =keys.concat(bbb)
       const hourIntr =  s.concat(varhour)
       
       var aaa = dtime.map((val) =>{
        bbb.splice(val, 1)
      });
      const ListItam = hourIntr.map((hourIntervals,i) =>
      // console.log("asiuiu",hourIntervals)
      <>
      <Col span={3}>
        <div style={{ display: "flex"}}>
        <FormItem>
        {getFieldDecorator(`slots[${i}]time`, {
          initialValue: hourIntervals,
                  rules: [
                    // {
                    //   type: 'email',
                    //   message: 'The input is not valid E-mail!',
                    // },
                    // {
                    //   required: true,
                    //   message: 'Please input your E-mail!',
                    // },
                  ],
                })(<Col md={{span:1}}><Button value={hourIntervals} onClick={()=>{ this.handleclick(i)}} style={{ marginTop: "5px" }} disabled={this.state.selected.indexOf(i) != -1 ? true : false}>{hourIntervals}</Button></Col>)}
                </FormItem>
                {/* </Col> */}
                {/* <Col span={2}> */}
        <FormItem>
        {getFieldDecorator(`slots[${i}]available`, {
          initialValue: true,
                  // rules: [
                  //   {
                  //     type: 'email',
                  //     message: 'The input is not valid E-mail!',
                  //   },
                  //   {
                  //     required: true,
                  //     message: 'Please input your E-mail!',
                  //   },
                  // ],
                })}
          </FormItem>
          </div>
        </Col>
      
      </>
      );
        return(
            <App header="Manage Rooms">
            <Form onSubmit={this.handleSubmit}>
            <Row gutter={16}>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                        <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                        <Link to="/roomslist">
                            <Breadcrumb.Item>Manage Rooms</Breadcrumb.Item>
                        </Link>
                        </Breadcrumb>
                    </Col>
                  
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                    <FormItem
                            label="Select Date"
                        >
                            {getFieldDecorator(`Date`, {
                                rules: [{
                                    required: true
                                }]
                            })(
                                <RangePicker  onChange={this.onChangedate}
                                format={dateFormat} />
                            )}
                        </FormItem>   
                    </Col>

            </Row>
            {this.state.date != null ?
            <div>
            <h4>Please select slots to make it unavailable</h4>
                <Row gutter={24} style={{ marginTop: 10 }} type="flex">{ListItam}</Row>
            </div>
            : ' '}  
      <Row gutter={16}>
        
                    <Col span={24}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginTop: "15px",marginBottom:"5%",marginRight:"20px" }} >
                            Save
                        </Button>
                        <Button type="default" style={{background:"#f44336",marginTop: "15px", color:"#fff",marginBottom:"5%" }}  onClick={this.handleclick1} >Cancel</Button>
                    </Col>    
                </Row>
            </Form>
            </App>
        )
    }
}
const WrappedCreateBulkSlot = Form.create()(CreateBulkSlot);
export default withApollo(WrappedCreateBulkSlot)