import gql from 'graphql-tag'
export default gql`
query getPropertyByMerchantId( $merchantId: String!){
  getPropertyByMerchantId( merchantId: $merchantId){
      property
      propertyId
      slugHotelName
      hotelName
      noOfRooms
      description
      roomCategory
      tel
      cleaningTime{
        type
        value
      }
      gstNo
      latlng{
        lat
        lng
      }
      discount
      location
      addressLineOne
      addressLineTwo
      city
      state
      zip
      country
      createdAt
      updatedAt
      enabled
      paymentMethod
      merchantId
      amenities
      policiesAndRules
      rate1
      rate2
      rate3
      rate4
      rate5
      attachedRoomCategories{
        property
        propertyId
        roomCategory
        baseRate1
        baseRate2
        additionalRate1
        additionalRate2
        guestRate1
        guestRate2
        size
        occupancy
      }
      additionalImages{
        bucket
          region
          key
  
      }
      
      # attachedRooms{
      #   id
      #   propertyId
      #   roomId
      #   roomNo
      #   merchantId
      #   enabled
      #   createdAt
      #   roomSlots{
      #     id
      #     roomId
      #     slotDate
      #     epochDate
      #     slotsList{
      #       time
      #       available
      #     }
      #     enabled
      #     createdAt
      #   }
        
      # }
  }
}
`