import React from 'react'
import { Icon, Button, Spin, Modal, Row,Col, Upload, Select , Tabs, Form, Input,message } from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import App from '../App'
import updatePropertyRoom from '../mutation/updatePropertyRoom'
const FormItem = Form.Item

class EditRoom extends React.Component{
    constructor(props){
        super(props)
        this.state={
            loading:false
        }
    }
    handleSubmit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
              console.log("form values",values)
              
              this.props.client.mutate({
                  mutation: updatePropertyRoom,
                  variables: {
                    propertyId: this.props.location.state.roomdata.propertyId,
                    roomId: this.props.location.state.roomdata.roomId,
                    roomNo: this.props.location.state.roomdata.roomNo,
                    merchantId: this.props.location.state.roomdata.merchantId,
                    enabled: this.props.location.state.roomdata.enabled,
                    attachedCategory:"default"
                  }
              }).then((data) => {
                  this.setState({
                      loading: false
                  })
                  message.success('Rooms Updated Successfully');
                  console.log(data)
              })
          }
        })
    }
    render(){
        console.log(this.props.location.state.roomdata)
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return(
            <App header={"Edit Room"}>
              <Form onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                  <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Occupancy"
                            >
                                {getFieldDecorator(`occupancy`, {
                                    // initialValue:this.state.merchantData!=null && this.state.merchantData.occupancy!=null?this.state.merchantData.occupancy:'',
                                    rules: [{
                                        // required: true,
                                        message: "Please enter Occupancy",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 1)){
                                    //           cb('Please enter 1 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Occupancy" style={{ width: '100%' }} />
                                )}
                        </FormItem>
                    </Col>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Room Size"
                            >
                                {getFieldDecorator(`size`, {
                                    rules: [{
                                        // required: true,
                                        message: "Please enter Room Size",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 1)){
                                    //           cb('Please enter 1 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Room Size" style={{ width: '100%' }} />
                                )}
                        </FormItem>
                    </Col>
                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Base Rate (2 hours) (week days) "
                            >
                                {getFieldDecorator(`baseRate1`, {
                                    //  initialValue:this.state.merchantData!=null && this.state.merchantData.baseRate1!=null?this.state.merchantData.baseRate1:'',
                                    rules: [{
                                        // required: true,
                                        message: "Please enter Rate week days",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length > 3)){
                                    //           cb('Please enter 3 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Week Days Rates" style={{ width: '100%' }} />
                                )}
                        </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Base Rate (2 hours) (weekend days)"
                            >
                                {getFieldDecorator(`baseRate2`, {
                                    //  initialValue:this.state.merchantData!=null && this.state.merchantData.baseRate2!=null?this.state.merchantData.baseRate2:'',
                                    rules: [{
                                        // required: true,
                                        message: "Please enter Rate weekend days",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length > 3)){
                                    //           cb('Please enter 3 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Weekend Days Rates" style={{ width: '100%' }} />
                                )}
                        </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Additional 1 hours Rate (week days)"
                            >
                                {getFieldDecorator(`additionalRate1`, {
                                    //  initialValue:this.state.merchantData!=null && this.state.merchantData.additionalRate1!=null?this.state.merchantData.additionalRate1:'',
                                    rules: [{
                                        // required: true,
                                        message: "Please enter Additional 1 hours Rate (week days)",
                                    }]
                                })(
                                    <Input placeholder="Additional 1 hours Rate (week days)" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Additional 1 hours Rate (weekend days)"
                            >
                                {getFieldDecorator(`additionalRate2`, {
                                    //  initialValue:this.state.merchantData!=null && this.state.merchantData.additionalRate2!=null?this.state.merchantData.additionalRate2:'',
                                    rules: [{
                                        // required: true,
                                        message: "Please enter Additional 1 hours Rate (weekend days)",
                                    }]
                                })(
                                    <Input placeholder="Additional 1 hours Rate (weekend days)" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Guest Rate (week days)"
                            >
                                {getFieldDecorator(`guestRate1`, {
                                    // initialValue: this.state.merchantData != undefined ? this.state.merchantData.guestRate1 : "--",
                                    rules: [{
                                        // required: true,
                                        message: "Please enter Guest Rate (week days)",
                                    }]
                                })(
                                    <Input placeholder="Guest Rate (week days)" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Guest Rate (weekend days)"
                            >
                                {getFieldDecorator(`guestRate2`, {
                                    // initialValue: this.state.merchantData != undefined ? this.state.merchantData.guestRate2 : "--",
                                    rules: [{
                                        // required: true,
                                        message: "Please enter Guest Rate (weekend days)",
                                    }]
                                })(
                                    <Input placeholder="Guest Rate (weekend days)" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }}  onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                </Row>
            </Form>
            </App>
        )
    }
}
const WrappedEditRoom = Form.create()(EditRoom);
export default withApollo(WrappedEditRoom)
