import React from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, message,Modal,Icon,InputNumber  } from 'antd'
import { graphql, compose, withApollo } from 'react-apollo';
import updateCheckedIn from '../mutation/updateCheckedIn'
const FormItem = Form.Item
const Option = Select.Option;
class CheckIn extends React.Component{
    constructor(props){
        super(props)
        this.state={
            idNumber:"",
            loading:false,
        }
    }
    enterLoading = ()=>{
        this.setState({
            loading:true
        })
    }
handleSubmit=(e)=>{
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.enterLoading();
        this.props.client.mutate({
            mutation: updateCheckedIn,
            variables: {
                id: this.props.data.id, 
                idType: values.idType,
                idNumber: values.idNumber
            }
        }).then((data) => {
          
            message.success('CheckIn Successful');
            
            this.setState({
                loading:false
            })
            // this.props.history.push('/')
            document.location.reload()
            console.log(data)
            })
      }
    });
}
handleclick =()=>{
    // this.props.history.push('/manage-booking')
}
handleChange=(value)=>{
    console.log("onSelect",value)
    this.setState({
        idNumber:value
    })
}
    render(){
        // console.log("checkIn data",this.props.data);
        var bookingData = this.props.data;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return(
            // <App header="Check In">
                <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Name">
                                {getFieldDecorator(`name`, {
                                   initialValue: bookingData != null ? bookingData.name : "--",
                                    rules: [{
                                        required: true,
                                        message: "Please enter Name",
                                    },
                                ]
                                })(
                                    <Input placeholder="Name" disabled="true" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Mobile">
                                {getFieldDecorator(`userId`, {
                                   initialValue: bookingData != null ? bookingData.userId : "--",
                                    rules: [{
                                        required: true,
                                        message: "Please enter Mobile Number",
                                    },
                                ]
                                })(
                                    <Input placeholder="Mobile" disabled="true" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Email">
                                {getFieldDecorator(`email`, {
                                   initialValue: bookingData != null ? bookingData.email : "--",
                                    rules: [{
                                        required: false,
                                        message: "Please enter Email",
                                    },
                                ]
                                })(
                                    <Input placeholder="Email" disabled="true" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Id Proof">
                                {getFieldDecorator(`idType`, {
                                   initialValue: bookingData != null ? bookingData.idType : '',
                                    rules: [{
                                        required: true,
                                        message: "Please Select Id Proof",
                                    },
                                ]
                                })(
                                    <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Select Id Proof"
                                            optionFilterProp="children"
                                            onChange={this.handleChange}
                                            filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                                <Option value="Aadhar">Aadhar Card</Option>
                                                <Option value="Pancard">Pan Card</Option>
                                                <Option value="Voter">Voter Id</Option>
                                                <Option value="Licence">Driving Licence</Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
            {this.state.idNumber==="Aadhar" &&
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Aadhar Number">
                                {getFieldDecorator(`idNumber`, {
                                //    initialValue: bookingData.idNumber != null ? bookingData.idNumber : '',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Aadhar Number",
                                    },
                                    {
                                        validator:(rule, value, cb)=>{
                                            if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 12)){
                                              cb('Please enter 12 digit Aadhar Number')
                                            }
                                            cb()
                                        }
                                    }
                                   
                                ]
                                })(
                                    <Input placeholder="Aadhar Number"  style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                      }
                       {this.state.idNumber==="Pancard" &&
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Pan Card Number">
                                {getFieldDecorator(`idNumber`, {
                                //    initialValue: bookingData.idNumber != null ? bookingData.idNumber : '',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Pan Number",
                                    },
                                    {
                                        validator:(rule, value, cb)=>{
                                            if(!value.match(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)){
                                              cb('Please enter Pan Number')
                                            }
                                            cb()
                                        }
                                    }
                                   
                                ]
                                })(
                                    <Input placeholder="Pan Card Number"  style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                      }
                {this.state.idNumber==="Voter" &&
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Voter Id">
                                {getFieldDecorator(`idNumber`, {
                                //    initialValue: bookingData.idNumber != null ? bookingData.idNumber : '',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Voter Id",
                                    },
                                    
                                     {
                                        validator:(rule, value, cb)=>{
                                            if( value != undefined && value!=""){
                                                if(!value.match(/^([a-zA-Z]){3}([0-9]){7}?$/)){
                                                    cb('Please enter valid Voter Id')
                                                 
                                                 }        
                                            }
                                            cb()
                                        }
                                    }
                                ]
                                })(
                                    <Input placeholder="Voter Id"  style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                      }
                {this.state.idNumber==="Licence" &&
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Driving Licence">
                                {getFieldDecorator(`idNumber`, {
                                //    initialValue: bookingData.idNumber != null ? bookingData.idNumber : '',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Driving Licence",
                                    },
                                    
                                    {
                                        validator:(rule, value, cb)=>{
                                            if( value != undefined && value!=""){
                                                if(!value.match(/^([a-zA-Z]){2}([0-9]){2}([0-9]){4}([0-9]){7}?$/)){
                                                    cb('Please enter valid Driving Licence No')
                                                 
                                                 }        
                                            }
                                            cb()
                                        }
                                    }
                                    
                                ]
                                })(
                                    <Input placeholder="Driving Licence"  style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                      }
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                CheckIn
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }}  onClick={this.handleclick}>Cancel</Button>
                        </Col>    
                    </Row>
            </Form>
            // </App>
        )
    }
}
const WrappedCheckIn = Form.create()(CheckIn);
export default withApollo(WrappedCheckIn)





