import gql from 'graphql-tag'
export default gql`
mutation updatePropertyRoom(
  $propertyId: String!
  $roomId: String!
  $roomNo: Int
  $merchantId: String!
  $enabled: Boolean!
  $attachedCategory: String
){
  updatePropertyRoom(input:{
    propertyId: $propertyId
    roomId: $roomId
    roomNo: $roomNo
    merchantId: $merchantId
    enabled: $enabled
    attachedCategory: $attachedCategory
  }){
    id
    propertyId
    roomId
    roomNo
    merchantId
    enabled
    createdAt
    attachedCategory
  }
}`
//  {
//        "propertyId": "HOTEL-29-star-hotel",
//        "roomId": "HOTEL-29-star-hotel::10",
//        "roomNo": 10,
//        "merchantId": "7f3aa1fe-2c6a-446b-a1b7-b83860a3cd06",
//        "enabled": true,
//        "attachedCategory": "test"
//      }