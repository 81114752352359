import React from 'react';
import { Suspense, lazy } from "react";
import { Button, Col, Icon, Layout, Menu,  Tooltip, Row } from 'antd';
import MediaQuery from 'react-responsive';
import { Link,withRouter } from 'react-router-dom';
import MLayout from './components/m/mLayout'
import { Auth } from "aws-amplify";
import { graphql, compose, withApollo } from 'react-apollo';
import getPropertyByMerchantId from './query/getPropertyByMerchantId'
import {MyContext} from './main'
const AdminApp = React.lazy(() => import('./components/AdminApp'));
const { Header, Sider,Footer, Content } = Layout;
const { SubMenu } = Menu;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      activemenuKey: '',
      defaultOpen: '',
      openKeys: [],
      role:'',
    };

    this.getData()
  }

  getData = async()=>{
   await  Auth.currentUserInfo()
    .then((data) => {
      console.log("user data",data)
      if(data.attributes['custom:role'] == undefined){
        localStorage.setItem('role',data.attributes['custom:refId'])
        this.setState({
          role:"merchant"
        })
        // console.log("merchant")
      }else{
        // localStorage.setItem('role',data.attributes['custom:role'])
        // console.log("Admin")
        this.setState({
          role:"Admin",
        })
      }
        // this.setState({
        //     role:data.attributes['custom:role'],
        // })
    })
    .catch(err => console.log(err));
  }

            static showMenuSelected(url) {
              const pathArr = url.split('/').filter(pathVal => pathVal != '');
              const pathName = pathArr[0];
              let activeKey = '0';

              switch (pathName) {
                case undefined:
                activeKey = '1';
                break;
                case 'merchant-profile':
                activeKey = '1';
                break;

                case 'manage-booking':
                activeKey = '6';
                break;

                case 'checkIn':
                activeKey = '6';
                break;

                case 'editcheckin':
                activeKey = '6';
                break;
                
                case 'userlist':
                activeKey = '2';
                break;

                case 'manage-property':
                activeKey = '7';
                break;

                case 'roomslist':
                activeKey = '8';
                break;

                case 'modelview':
                activeKey = '8';
                break;

                case 'viewslots':
                activeKey = '8';
                break;

                case 'cratebulkslot':
                activeKey = '8';
                break;

                case 'allrooms':
                activeKey = '8'
                break;
                
                case 'bulkslotsallroom':
                activeKey = '8'
                break;

                case 'edit_slots':
                activeKey = '8'
                break;

                case 'edit_room':
                activeKey = '8'
                break;

                case 'reviews':
                activeKey = '9'
                break;
                
                case 'Setting':
                activeKey = '10';
                break;
                default:
                activeKey = '4';
              }
              return {
                activeKey
              };
            }
            static getDerivedStateFromProps(nextProps, nextState) {
              const getActiveMenuId = App.showMenuSelected(nextProps.match.url);
              return {
                activemenuKey: getActiveMenuId.activeKey,
              };
          
          }

            toggle = () => {
              this.setState({
                collapsed: !this.state.collapsed,
              });
            };

          handleClick = (e) => {
            // console.log('click ', e);
            // console.log('key',e.keyPath[1])
        
          }
        
          onCollapse = collapsed => {
            this.setState({ collapsed });
          };
          onOpenChange = openKeys => {
            const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
              this.setState({ openKeys });
            } else {
              this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
              });
            }
          };
    //       componentWillMount(){
             
    // var rid = localStorage.getItem('role')
    
    // this.props.client.query({
    //          query:getPropertyByMerchantId,
    //          variables:{ merchantId:rid,
    //                         },
    //          })
    //          .then(({ data }) => {
    //            console.log("AjayAjay form App",data.getPropertyByMerchantId.propertyId)
    //            localStorage.setItem('propertyId', data.getPropertyByMerchantId.propertyId);
    //          })
    //          .catch(err => {
    //              console.log(`Error ${JSON.stringify(err)}`)
    //          })
    //       }
          handleSignOut = () => {
            localStorage.removeItem("myData");
            localStorage.removeItem("propertyId");
            localStorage.removeItem("refId");
            localStorage.removeItem("username");
            // localStorage.removeItem("terms"); 
               Auth.signOut()
              .then(() => {
                window.location = window.location.origin;
                // window.location.reload(true)
              })
              .catch(err => console.log(err));
          };
 
  render(){
    const { role, user } = this.state;
    const {children} = this.props
    const { activemenuKey } = this.state;
  return (
    // <MediaQuery minDeviceWidth={700}>
    //   {matches => {
    //       if (matches) {
    //         return (
    //           <>
                <Layout>
                  <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={broken => {
                      console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                      console.log(collapsed, type);
                    }}
                  >
                       <div
                    className="logo"
                    style={{
                      height: '37px',
                      margin: '16px'
                    }}
                  >
                    {/* {this.state.collapsed ? (
                      <img
                        src="https://www.recaho.com/img/xyz/logo.png"
                        className="header-logo"
                        style={{ width: '30px' }}
                      />
                    ) : ( */}
                      <img
                        src={require("./img/recaho2.png")}
                        style={{ width: '100%', height: '40px' }}
                      />
                    {/* )} */}
                  </div>
                    <Menu theme="dark" mode="inline" selectedKeys={[activemenuKey]}>
                      {/* <Menu.Item key="1">
                      <Link to="/marchant">
                        <Icon type="user" />
                        <span className="nav-text">Manage Merchant</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="5">
                      <Link to="/marchant-request">
                        <Icon type="user-add" />
                        <span className="nav-text">Merchant Request</span>
                        </Link>
                      </Menu.Item> */}
                      <Menu.Item key="1">
                      <Link to="/">
                        <Icon type="user" />
                        <span className="nav-text">Manage Profile</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="6">
                        <Link to="/manage-booking">
                        <Icon type="calendar" />
                        <span className="nav-text">Manage Bookings</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="7">
                        <Link to="/manage-property">
                        <Icon type="home" />
                        <span className="nav-text">Manage Property</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="8">
                        <Link to="/roomslist">
                        <Icon type="home" />
                        <span className="nav-text">Manage Rooms</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="9">
                        <Link to="/reviews">
                        <Icon type="user" />
                        <span className="nav-text">User Review</span>
                        </Link>
                      </Menu.Item>
                    </Menu>
                  </Sider>
                  <Layout style={{ height: "100vh" }}>
                    <Header style={{ background: '#fff', padding: 0 }} >
                    <Row>
                      <Col span={20}>
                    {/* <Icon
                          className="trigger"
                          type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                          onClick={this.toggle}
                        /> */}
                    <h2 style={{marginLeft:'20px'}}>{this.props.header}</h2>
                      </Col>
                      <Col>
                      <div style={{cursor:"pointer", padding:"10px",float:"right"}} onClick={this.handleSignOut}>
                      <Tooltip placement="left"  title="Logout">
                      <Icon type="logout"   style={{ fontSize: '30px', color: '#08c'}}/>
                      </Tooltip>
                      </div>
                      </Col>
                    </Row>
                    </Header>
                    <Content style={{ margin: '10px 10px 0', padding: 10,
                      background: '#fff',
                      overflow: 'auto' }}>
                      <div style={{ padding: 10, background: '#fff', minHeight: 560 }}>
                      {this.props.children}
                      </div>
                    </Content>
                    {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
                  </Layout>
                </Layout>
      
    //   </>
    //         );
    //   }else{
    //     return <MLayout siteType={this.props.siteType}>{children}</MLayout>;
    //   }}
    // }
    // </MediaQuery>
  );
}
}
App.contextType = MyContext
export default withRouter(withApollo(App));
