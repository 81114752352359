import gql from 'graphql-tag'
export default gql`
query getBookingByStatusByProperty( $propertyId: String! $status: String!){
  getBookingByStatusByProperty(propertyId: $propertyId status: $status){
    id
    propertyName
    propertyId
    roomId
    status
    userId
    name
    email
    totalAmt
    couponAmt
    bookingSlot
    noOfRooms
    noOfGuests
    bookingDate
    location
    addressLineOne
    addressLineTwo
    city
    state
    zip
    country
    idType
    idNumber
    createdAt
    checkInAt
    checkOutAt
    extendedAmt
    extendedSlots
    grandTotal
    couponCode
    cancelledAt
    paymentState
    paymentMode
    razorpayId
  }
}
`