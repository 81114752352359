import React from 'react'
import { Icon, Button, Spin, Input, Row,Col, Table, Modal, Popconfirm, Form, Tabs,message } from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import App from '../App'
import CheckedIn from './CheckedIn'
import Checkout from './checkout'
import CheckIn from './CheckIn'
import CanseledBooking from './Canceled_booking'
import getBookingByStatusByProperty from '../query/getBookingByStatusByProperty';
import getPropertyByMerchantId from '../query/getPropertyByMerchantId'
import {MyContext} from '../main'
import BookingDe from './bookingDe'

const { TabPane } = Tabs;
function callback(key) {
    // console.log(key);
  }
  
class ManageBooking extends React.Component{
    constructor(props){
        super(props)
        this.state={
          loading:false,
          bookingData:[],
          dataSource:[],
          refId:'',
          visible: false,
          visibleNew:false,
          bookingD:[]

        }
    }
    handleOk1 = e => {
      // console.log(e);
      this.setState({
        visibleNew: false,
      });
    };
  
    handleCancel1 = e => {
      // console.log(e);
      this.setState({
        visibleNew: false,
      });
    };

    enterLoading = ()=>{
      this.setState({
          loading:true
      })
  }
  // componentDidMount(){
  //   var rid = localStorage.getItem('refId')
  //   this.props.client.query({
  //            query:getPropertyByMerchantId,
  //            variables:{ merchantId:rid,
  //                           },
  //            })
  //            .then(({ data }) => {
  //              console.log("AjayAjay form App",data.getPropertyByMerchantId.propertyId)
  //              this.setState({
  //                refId:data.getPropertyByMerchantId.propertyId
  //              },
               
  //              () =>(
  //               this.getpropertyId(this.state.refId)
  //              ));
  //            })
  //            .catch(err => {
  //                console.log(`Error ${JSON.stringify(err)}`)
  //            })
  //         }
          componentDidMount(){
            this.enterLoading()
            var propertyId = localStorage.getItem('propertyId')
            this.props.client.query({
              query:getBookingByStatusByProperty,
              variables:{ propertyId:propertyId,
                          status: "NEW",
              },
              })
              .then(({ data }) => {
                this.createDatasource(data.getBookingByStatusByProperty)
                 this.setState({
                  bookingData: data.getBookingByStatusByProperty,
                  loading:false
                });
              })
              .catch(err => {
                  console.log(`Error ${JSON.stringify(err)}`)
                  this.setState({
                    loading:false
                  });
              })

          }
  // getpropertyId=(refId)=>{
  //   this.enterLoading()
  //   var propertyId = localStorage.getItem('propertyId')
  //     console.log("propertyId",refId)
  //     this.props.client.query({
  //         query:getBookingByStatusByProperty,
  //         variables:{ propertyId:propertyId,
  //                     status: "NEW",
  //         },
  //         })
  //         .then(({ data }) => {
  //           this.createDatasource(data.getBookingByStatusByProperty)
  //            this.setState({
  //             bookingData: data.getBookingByStatusByProperty,
  //             loading:false
  //           });
  //         })
  //         .catch(err => {
  //             console.log(`Error ${JSON.stringify(err)}`)
  //         })
  //     }
      createDatasource=(data)=>{
        let rId = []

        let dataSource = data.map((m,i)=>{
          //  console.log("roomidS",rId)
          return{
            key:i,
            id: m.id,
            bookingDate: m.bookingDate,
            bookingSlot: m.bookingSlot,
            totalAmt: m.totalAmt,
            noOfRooms: m.noOfRooms,
            name:m.name,
            userId:m.userId,
            noOfGuests:m.noOfGuests,
            Inslot: m.bookingDate +' '+ m.bookingSlot[0],
            outSlot: m.bookingDate +' '+m.bookingSlot.slice(-1)[0],
            roomId:  m.roomId,
            email:m.email,
            couponCode:m.couponCode,
            couponAmt:m.couponAmt,
            createdAt:m.createdAt,
            paymentState:m.paymentState,

          }
        })
        this.setState({
          dataSource:dataSource
        })
    }
    getColumnSearchProps = dataIndex => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search here`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        if (dataIndex == 'upn') {
          return record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        } else {
          let a = dataIndex.split('.')[1].toString();
          return record.masterVariationDump[a]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
      render: text => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      )
    });
  
    handleSearch = (selectedKeys, confirm) => {
      confirm();
      this.setState({ searchText: selectedKeys[0], onSearch: true,search:true });
    };
  
    handleReset = clearFilters => {
      clearFilters();
      this.setState({
        searchText: '',
        nextToken: '',
        productList: [],
        checkdata:{},
        loading: false,
        hasMore: true,
        search:false
      });
    };
    showModal = (data) => {
      // console.log("sssss",data)
      this.setState({
        visible: true,
        checkdata:data
        // state:{data}
      });
    };
    handleOk = () => {
      this.setState({
        visible: false,
      });
      // window.location.reload()
    }
    handleCancel = () => {
      this.setState({
        visible: false,
      });
      // window.location.reload()
    }
 

  onSelect = (record) => {
    console.log("record", record)
    this.setState({
      bookingD: record,
      visibleNew: true
    })
  }

    render(){
      // console.log("context",this.context)
      // console.log("booking data ren",this.state.dataSource)
      console.log("bookingD",this.state.bookingD)
        const columns = [
            {
                title: 'Booking ID',
                width: 80,
                dataIndex: 'id',
                key: 'id',
                ...this.getColumnSearchProps('id'),
              },{
                title: 'Name',
                width: 110,
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
              },{
                title: 'Mobile',
                width: 110,
                dataIndex: 'userId',
                key: 'userId',
                ...this.getColumnSearchProps('mob'),
              },
            {
                title: 'Check-In Time',
                width: 145,
                dataIndex: 'Inslot',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              },{
                title: 'Check-Out Time',
                width: 145,
                dataIndex: 'outSlot',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              }, 
            //   {
            //   title: 'Time',
            //   dataIndex: 'timeSlot',
            //   width: 125,
            //   key: '',
            //   // ...this.getColumnSearchProps('fName'),
            // }, 
            {
              title: 'Number of Guests',
              width: 110,
              dataIndex: 'noOfGuests',
              key: '',
              // ...this.getColumnSearchProps('lName'),
          
            },
            {
              title: 'Number of Rooms',
              width: 130,
              dataIndex: 'noOfRooms',
              key: '',
              // ...this.getColumnSearchProps('lName'),
          
            },
            {
              title: 'Room Number',
              width: 130,
              // dataIndex: 'roomId',
              key: '',
              render :(data)=>{
                // console.log("table room id",data.roomId)
                var arrs = [];
               var ids =  data.roomId.map((val)=>{
                return val.split("::")
               })
              //  console.log("ids",ids[1])
               if(ids.length >=1){
                 ids.map((val)=>{
                  //  console.log("final ids",val[1])
                  arrs.push(val[1])
                 })
               }
              //  console.log("only ids",arrs)
                return(
              
                <div>{arrs.join(', ')}</div>
                )
              }
              // ...this.getColumnSearchProps('lName'),
          
            },
               {
              title: 'Amount',
              dataIndex: 'totalAmt',
              width: 110,
              key: '',
              // ...this.getColumnSearchProps('fName'),
            }, 
            // {
            //     title: 'Status',
            //     dataIndex : 'status',

            // }, 
            {
                title: 'Action',
                key: 'action',
                width: 180,
                render: (data) => {
                        return (
                            <span>
                                {/* <Link to={{pathname:"/checkIn",
                                    state:{data}
                                }}>
                                    <Button icon="check" type="primary" style={{marginRight:"10px",ursor: "pointer", background:"##f44336 "}}>Check In</Button>
                                </Link> */}
                                <Button type="primary" icon="check" style={{ cursor: "pointer",marginRight: '5px' }} onClick={()=>{this.showModal(data)}} />
                                {/* <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data.mob,data.userName)}>
                                    <Button icon="delete" type= "danger" style={{marginLeft:"5px",ursor: "pointer", background:"#ff0000 ", color:"#fff"}}/>
                                </Popconfirm> */}
                                <Button type="primary" icon="eye" style={{ cursor: "pointer" }} onClick={()=>{this.onSelect(data)}}/>

                            </span>
                        )
                    }
                
        }
             ];
        return(
            <App header={"Manage Bookings"}>
                <Tabs onChange={callback} type="card">
                <TabPane tab="New" key="1">
            <Table loading={this.state.loading} dataSource={this.state.dataSource} columns={columns} pagination={{ pageSize: 10 }} bordered 
                //  onRow={(record, rowIndex) => {
                //   return {
                //     onClick: event => {
                //       this.onSelect(record)
                //     }
                //   };
                // }}
                 />
            <Modal
                                                title="CheckIn Details"
                                                visible={this.state.visible}
                                                onOk={this.handleOk}
                                                onCancel={this.handleCancel}
                                                width={"60%"}
                                                footer={false}
                                            >
                                          <CheckIn data={this.state.checkdata} />
                                              </Modal>
        
            </TabPane>
            <TabPane tab="In Process" key="2" >
            {this.state.loading ? <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
                  </div> : <CheckedIn refId={this.state.refId}/>}
            </TabPane>
            <TabPane tab="Completed" key="3" >
            {this.state.loading ? <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
                  </div> : <Checkout refId={this.state.refId}/>}
            </TabPane>
            <TabPane tab="Cancelled" key="4" >
            {this.state.loading ? <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
                  </div> : <CanseledBooking refId={this.state.refId}/>}
            </TabPane>
            {/* <TabPane tab="Approved" key="2" >
            <Table dataSource={data} columns={columns} bordered />
            </TabPane>
            <TabPane tab="Rejected" key="3">
            <Table dataSource={data} columns={columns} bordered />
            </TabPane>
            <TabPane tab="Completed " key="4">
            <Table dataSource={data} columns={columns} bordered />  
            </TabPane> */}
            </Tabs>

            <Modal
          title="Booking Details"
          visible={this.state.visibleNew}
          onOk={this.handleOk1}
          onCancel={this.handleCancel1}
          footer={false}
          width={800}
        >
         <BookingDe data={this.state.bookingD}/>

        </Modal>
      

            </App>
        )
    }
}
ManageBooking.contextType = MyContext
export default withApollo(ManageBooking)