import React from 'react'
import App from '../App'
class Userlist extends React.Component{
    constructor(props){
    super(props)
    this.state={

    }
}
render(){
    return(
        <App header={"Userlist"}>
        <h1>hello ajay</h1>
        </App>
    );
}
}
export default Userlist