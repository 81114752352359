import gql from 'graphql-tag'
export default gql`
mutation createBulkSlot( $input: [CreateSlotInput]!){
    createBulkSlot(input: $input)
  }`
  // {
  //     "input": [
  //       {
  //       "roomId": "21-prince-hotel::1",
  //       "slotDate": "10-12-2019",
  //       "epochDate": 1575987175,
  //       "slotsList": [
  //         {
  //         "available": true,
  //         "time": "11:00 AM"
  //       },
  //       {
  //         "available": true,
  //         "time": "12:00 PM"
  //       },
  //       {
  //         "available": true,
  //         "time": "01:00 PM"
  //       },
  //         {
  //         "available": true,
  //         "time": "2:00 AM"
  //       },
  //       {
  //         "available": true,
  //         "time": "3:00 PM"
  //       },
  //       {
  //         "available": true,
  //         "time": "4:00 PM"
  //       }
  //       ]
  //       },
  //       {
  //         "roomId": "21-prince-hotel::1",
  //         "slotDate": "11-12-2019",
  //         "epochDate": 1576073575,
  //         "slotsList": [
  //          {
  //         "available": true,
  //         "time": "11:00 AM"
  //       },
  //       {
  //         "available": true,
  //         "time": "12:00 PM"
  //       },
  //       {
  //         "available": true,
  //         "time": "01:00 PM"
  //       },
  //         {
  //         "available": true,
  //         "time": "2:00 AM"
  //       },
  //       {
  //         "available": true,
  //         "time": "3:00 PM"
  //       },
  //       {
  //         "available": true,
  //         "time": "4:00 PM"
  //       },
  //           {
  //         "available": true,
  //         "time": "5:00 PM"
  //       }
  //         ]
  //       }
  //     ]
  //   }