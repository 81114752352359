import gql from 'graphql-tag'
export default gql`
mutation createSlot(
  $roomId: String!
  $slotDate: String!
  $slotsList: [SlotsListInput!]!
  $epochDate: Int!
  $enabled: Boolean
){
  createSlot(input:{
    roomId: $roomId
    slotDate: $slotDate
    slotsList: $slotsList
    epochDate: $epochDate
    enabled: $enabled
  }){
    id
    roomId
    slotDate
    slotsList{
      time
      available
    }
    epochDate
    enabled
    createdAt
  }
}
`