import gql from 'graphql-tag'
export default gql`
query getRoomsByPropertyId( $propertyId: String!){
  getRoomsByPropertyId(propertyId: $propertyId){
    id
    propertyId
    roomId
    roomNo
    merchantId
    enabled
    createdAt
  }
}
`