import React from 'react'
import { Icon, Button, Spin, Input, Row,Col, Table, Divider, Popconfirm, Form, Tabs,message } from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import updateCheckedOut from '../mutation/updateCheckedOut';
import getBookingByStatusByProperty from '../query/getBookingByStatusByProperty';

const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
  }
  
class CheckedIn extends React.Component{
    constructor(props){
        super(props)
        this.state={
          loading:false,
          bookingData:[],
          visible: false,
          dataSource:[]

        }
    }

    enterLoading = ()=>{
      this.setState({
          loading:true
      })
  }
  componentDidMount(){
    this.enterLoading()
    var propertyId = localStorage.getItem('propertyId')
      this.props.client.query({
          query:getBookingByStatusByProperty,
          variables:{ propertyId: propertyId,
                      status: "CHECK_IN",
          },
          fetchPolicy: 'network-only' ,
          })
          .then(({ data }) => {
            console.log("booking data",data)
            this.createDatasource(data.getBookingByStatusByProperty)
             this.setState({
              bookingData: data.getBookingByStatusByProperty,
              loading:false
            });
          })
          .catch(err => {
              console.log(`Error ${JSON.stringify(err)}`)
          })
      }
      createDatasource=(data)=>{
        let rId = []

        let dataSource = data.map((m,i)=>{
          m.roomId.map((val)=>{
           rId = val.split("::")
            console.log("roomidS",rId[1])
          })
          return{
            key:i,
            id: m.id,
            bookingDate: m.bookingDate,
            bookingSlot: m.extendedSlots == null ? m.bookingSlot :m.extendedSlots,
            totalAmt: m.grandTotal,
            roomId: m.roomId,
            name:m.name,
            userId:m.userId,
            noOfGuests:m.noOfGuests,
            Inslot: m.bookingDate +' '+ m.bookingSlot[0],
            outSlot: m.extendedSlots == null ? m.bookingDate +' '+m.bookingSlot.slice(-1)[0] : m.bookingDate +' '+m.extendedSlots.slice(-1)[0]  ,
            extendedAmt:m.extendedAmt,
            noOfRooms:m.noOfRooms,
            email:m.email
          }
        })
        this.setState({
          dataSource:dataSource
        })
    }
      handleCheckout =(id,ref)=> {
        this.enterLoading()
      this.props.client.mutate({
        mutation: updateCheckedOut,
        variables:{
          id: id,
        }
      }).then(({data})=>{
          console.log("change status data",data)
          message.success("Checkout successfully")
          
        if(data.updateCheckedOut){
          let arr = this.state.dataSource;
          console.log('arr',arr)
          arr.splice(id.key,1)
          this.setState({
            dataSource:arr,
            loading:false
          })
          document.location.reload()
          // message.success("Property deleted successfully")
          
        }
    })
   }
   getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div className="custom-filter-dropdown">
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search here`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex == 'upn') {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        let a = dataIndex.split('.')[1].toString();
        return record.masterVariationDump[a]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0], onSearch: true,search:true });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({
      searchText: '',
      nextToken: '',
      productList: [],
      loading: false,
      hasMore: true,
      search:false
    });
  };
  handleclick1=()=>{
    message.success('Already Extended');
  }
 
    render(){
      console.log("propertyId",this.props.refId)
        const columns = [
            {
                title: 'Booking ID',
                width: 75,
                dataIndex: 'id',
                key: 'id',
                ...this.getColumnSearchProps('id'),
              },{
                title: 'Name',
                width: 125,
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
              },{
                title: 'Mobile',
                width: 120,
                dataIndex: 'userId',
                key: 'userId',
                ...this.getColumnSearchProps('userId'),
              },
            {
                title: 'Check-In Time',
                width: 150,
                dataIndex: 'Inslot',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              },{
                title: 'Check-Out Time',
                width: 150,
                dataIndex: 'outSlot',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              }, 
            //   {
            //   title: 'Time',
            //   dataIndex: 'timeSlot',
            //   width: 125,
            //   key: '',
            //   // ...this.getColumnSearchProps('fName'),
            // }, 
            {
              title: 'Number of Guests',
              width: 95,
              dataIndex: 'noOfGuests',
              key: '',
              // ...this.getColumnSearchProps('lName'),
          
            },
               {
              title: 'Amount',
              dataIndex: 'totalAmt',
              width: 90,
              key: '',
              // ...this.getColumnSearchProps('fName'),
            }, 
            // {
            //     title: 'Status',
            //     dataIndex : 'status',

            // }, 
            {
                title: 'Action',
                key: 'action',
                width: 260,
                render: (data) => {
                  console.log("clicked data in booking",data)
                        return (
                            <span>
                                {data.extendedAmt == null ?
                                <Link to={{pathname:"/editcheckin",
                                    state:{data}
                                }}>
                                
                                    <Button icon="edit" type="primary" style={{marginRight:"10px",ursor: "pointer", background:"##f44336 "}}>Extend</Button>   
                                   
                                </Link>
                                 :  <Button icon="edit" type="primary" onClick={this.handleclick1} style={{marginRight:"10px",ursor: "pointer", background:"##f44336 "}}>Extend</Button>}
                                <Popconfirm title="Sure to Checkout?" onConfirm={() => this.handleCheckout(data.id,data.userId)}>
                                    <Button icon="check" type="danger" style={{ursor: "pointer", background:"##f44336 "}}>Check Out</Button>
                                </Popconfirm>
                                    {/* <Button type="primary" icon="edit" style={{ cursor: "pointer",marginRight:"10px" }}/>
                                    <Button type="danger" icon="close" style={{ cursor: "pointer",marginRight:"10px" }}/> */}
                             
                                {/* <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data.mob,data.userName)}>
                                    <Button icon="delete" type= "danger" style={{marginLeft:"5px",ursor: "pointer", background:"#ff0000 ", color:"#fff"}}/>
                                </Popconfirm> */}
                            </span>
                        )
                    }
                
        }
             ];
        return(
            <Table columns={columns} loading={this.state.loading} dataSource={this.state.dataSource} pagination={{ pageSize: 10 }} bordered />
        )
    }
}
export default withApollo(CheckedIn)