import React from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, message,Modal,Icon,Spin  } from 'antd'
import { graphql, compose, withApollo,Query } from 'react-apollo';
import updateMerchant from '../mutation/updateMerchant'
import getMerchantByMob from '../query/getMerchantByMob'
import App from '../App'
import {MyContext} from '../main'

const FormItem = Form.Item
const Option = Select.Option;
const gender = [{key:0, label:"Male", value:'MALE'},{key:1, label:"Female", value:"FEMALE"}]
const dateFormat = 'YYYY/MM/DD';
const country = [
    { value: 'IND', label: 'India' },
    { value: 'USA', label: 'USA' },
    { value: 'UK', label: 'UK '}
]

const columns = [{
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
}, {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
}, {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
}, {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
}, {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
}]

const state = [
    {value:'JAMMU_KASHMIR',label:'JAMMU KASHMIR'},
    {value:'HIMACHAL_PRADESH',label:'HIMACHAL PRADESH'},
    {value:'PUNJAB',label:'PUNJAB'},
    {value:'DELHI',label:'DELHI'},
    {value:'RAJASTHAN',label:'RAJASTHAN'},
    {value:'UTTARAKHAND',label:'UTTARAKHAND'},
    {value:'UTTAR_PRADESH',label:'UTTAR PRADESH'},
    {value:'WEST_BENGAL',label:'WEST BENGAL'},
    {value:'BIHAR',label:'BIHAR'},
    {value:'JHARKHAND',label:'JHARKHAND'},
    {value:'ODISHA',label:'ODISHA'},
    {value:'ASSAM',label:'ASSAM'},
    {value:'ARUNACHAL_PRADESH',label:'ARUNACHAL PRADESH'},
    {value:'MEGHALAYA',label:'MEGHALAYA'},
    {value:'TRIPURA',label:'TRIPURA'},
    {value:'MIZORAM',label:'MIZORAM'},
    {value:'MANIPUR',label:'MANIPUR'},
    {value:'NAGALAND',label:'NAGALAND'},
    {value:'MAHARASHTRA',label:'MAHARASHTRA'},
    {value:'GUJARAT',label:'GUJARAT'},
    {value:'GOA',label:'GOA'},
    {value:'CHHATTISGARH',label:'CHHATTISGARH'},
    {value:'MADHYA_PRADESH',label:'MADHYA PRADESH'},
    {value:'ANDHRA_PRADESH',label:'ANDHRA PRADESH'},
    {value:'HARYANA',label:'HARYANA'},
    {value:'KERALA',label:'KERALA'},
    {value:'SIKKIM',label:'SIKKIM'},
    {value:'TAMIL_NADU',label:'TAMIL NADU'},
    {value:'TELANGANA',label:'TELANGANA'},
    {value:'KARNATAKA',label:'KARNATAKA'},
]
function onChange(value) {
    console.log('changed', value);
  }
class MerchantProfile extends React.Component{

    constructor(props){
        super(props)
        this.state={
            selectedZipcode: ['all'],
            selectedCity: '',
            selectedState: '',
            selectedCountry: '',
            countryData: [],
            stateData: [],
            cityData: [],
            zipData: ['all'],
            selectedLocations: [],
            locationError: false,
            dataSource:[],
            name:'',
            categoryData: [],
            iconImg: {},
            removedIconImg: false,
            value: true,
            loading:false,
            loadingB:false,
            aHolder:false,
            aNo:false,
            bName:false,
            branch_Name:false,
            ifsc_Code:false,
            bankData:{},
            username: ''
        }
    }
    // static getDerivedStateFromProps(nextprops,prevState){
    //     return{
    //         username: nextprops.userdata.username
    //     }
    // }
   
    // componentDidMount(){
       
    //     console.log("rid from main",rid)
    //          this.props.client.query({
    //                   query:getPropertyByMerchantId,
    //                   variables:{ merchantId:rid,
    //                                  },
    //                   })
    //                   .then(({ data }) => {
    //                     console.log("AjayAjay form main",data.getPropertyByMerchantId.propertyId)
    //                     localStorage.setItem('propertyId', data.getPropertyByMerchantId.propertyId);
    //                   })
    //                   .catch(err => {
    //                       console.log(`Error ${JSON.stringify(err)}`)
    //                   })
     
    //       }

// bank details 

    //   bankDetailsfun=(data)=>{
          
    //       console.log("bank fun",data)
    //       this.setState({
    //         bankData:data.bankDetails
    //       })
    //     }
    //       if(data!=undefined && data.bankDetails!=undefined && data.bankDetails.accHolder!=undefined){
    //             this.setState({
    //                 aHolder:true
    //             })
    //       }

    //       if(data!=undefined && data.bankDetails!=undefined && data.bankDetails.accNo!=undefined){
    //            this.setState({
    //                 aNo:true
    //             })
    //     }
    //     if(data!=undefined && data.bankDetails!=undefined && data.bankDetails.bankName!=undefined){
    //         this.setState({
    //             bName:true
    //         })
    //     }
    //     if(data!=undefined && data.bankDetails!=undefined && data.bankDetails.branchName!=undefined){
    //         this.setState({
    //             branch_Name:true
    //         })
    //     }
    //     if(data!=undefined && data.bankDetails!=undefined && data.bankDetails.ifscCode!=undefined){
    //         this.setState({
    //             ifsc_Code:true
    //         })
    //     }

    //   }


    enterLoading = ()=>{
        this.setState({
            loading:true
        })
    }


    handleMainImage = ({ file }) => {
        this.setState((prevState, props) => {
            if(Object.keys(prevState.iconImg).length === 0 && prevState.removedIconImg === false){
                return{
                    iconImg: file,
                    removedIconImg: false
                }
            }
            else{
                return{
                    // iconImg: {},
                    removedIconImg: false
                }
            }
        });
    }

    handleMainImageRemove = (file) => {
        this.setState({ iconImg: {}, removedIconImg: true })
        return true;
    }
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleSubmit=(e)=>{
        e.preventDefault();

        
        this.props.form.validateFields((err, values) => {
            
            if(!err){
            console.log('Received values of form: ', values);
            this.enterLoading()
            try{
                this.props.client.mutate({
                    mutation: updateMerchant,
                    variables: {
                        status:"APPROVED",
                        mob:values.mob,
                        email:values.email,
                        fName:values.fName,
                        lName:values.lName,
                        hotelName:values.hotelName, 
                        // noOfRooms: values.noOfRooms,
                        tel:values.tel,
                        gstNo:values.gstNo,
                        panNo:values.panNo,
                        isReady: "true",
                        bankDetails:{
                            bankName:values.bankName,
                            accNo:values.accNo,
                            ifscCode:values.ifscCode,
                            accHolder:values.accHolder,
                            branchName:values.branchName
                        },
                        address:{
                        addressLineOne:values.addressLineOne,
                        addressLineTwo:values.addressLineTwo,
                        city:values.city,
                        state:values.state,
                        zip:values.zip,
                        country:"IND",
                        },
                    }
            }).then(({data}) => {
                console.log(data)
                // if(data.updateMerchant != null){
                //     message.success('Merchant Update Successful');
                
                  this.setState({
                    loading:false,
                   
                  })
                // }
                message.success('Merchant Update Successful');
                this.handleclick()
                })
                .catch(err =>{
                    console.log('in catch :: ',err)
                    this.setState({
                        loading:false,
                      })
                });
           
            }
            catch(err){
                console.log("error",err)
                this.setState({
                    loading:false,
                  })
            }
            }
    
            
            else{
                console.log(err)

            }
        })
    }
    handleOpen = () => this.setState({ visible: !this.state.visible })
    handleCancel = () => this.setState({visible:false})
    getLatLong = (position) => {
        this.setState({
            lat:position.lat(),
            lng:position.lng()
        })
    }
    handleclick =()=>{
        document.location.reload()
    }
    render(){
        var username = localStorage.getItem('username')
//    console.log("props data",this.context.attributes)
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { previewVisible, previewImage, iconImg } = this.state;

       
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>Upload</div>
            </div>
            
            );
        
        // const stateSorted = _.sortBy(state, ['label'])
        return(
            <App header="Manage Profile">
               
                 <Query query={getMerchantByMob} fetch="network-only" variables={{ mob: username}}>
                 {(
                     {loading,error,data}
                 )=>{
                     let merchantData = data.getMerchantByMob
                     if(loading) return(
                         <div style={{textAlign:"center"}}>
                             <Spin size="large"></Spin>
                         </div>
                     )
                     if(error) return `Error!:${error}`

                     if(!loading){
                         console.log("Query data",data)
                         return(
                            <Form onSubmit={this.handleSubmit}>
                            <Row gutter={16}>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem label="Hotel Name">
                                        {getFieldDecorator(`hotelName`, {
                                         
                                           initialValue: merchantData != undefined ? merchantData.hotelName : "--",
                                            rules: [{
                                                required: true,
                                                message: "Please enter Hotel Name",
                                            },
                                        ]
                                        })(
                                            <Input placeholder="Hotel Name" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="Email"
                                    >
                                        {getFieldDecorator(`email`, {
                                           
                                           initialValue: merchantData != undefined ? merchantData.email : "--",
                                            rules: [{
                                                type: 'email',
                                                required: true,
                                                message: "Please enter E-mail!",
                                            },
                                            // {
                                    
                                            //     validator:(rule, value, cb)=>{
                                            //       if(value){
                                            //         if(!value.match( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                                            //           cb('Please enter valid Email address')
                                            //         }
                                            //       }
                                            //         cb()
                                            //     }},
                                            
                                        
                                        ]
                                        })(
                                            <Input placeholder="Email" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem label="First Name">
                                        {getFieldDecorator(`fName`, {
                                           
                                           initialValue: merchantData != undefined ? merchantData.fName : "--",
                                            rules: [{
                                                required: true,
                                                message: "Please enter First Name",
                                            }]
                                        })(
                                            <Input placeholder="First Name" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="Last Name"
                                    >
                                        {getFieldDecorator(`lName`, {
                                           
                                           initialValue: merchantData != undefined ? merchantData.lName : "--",
                                            rules: [{
                                                required: true,
                                                message: "Please enter Last Name",
                                            }]
                                        })(
                                            <Input placeholder="Last Name" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="Mobile Number"
                                    >
                                        {getFieldDecorator(`mob`, {
                                            initialValue: merchantData != undefined ? merchantData.mob : "--",
                                            rules: [{
                                                required: true,
                                                message: "Please enter Mobile Number",
                                            },
                                            {
                                                validator:(rule, value, cb)=>{
                                                    if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)){
                                                      cb('Please enter 10 digit number only')
                                                    }
                                                    cb()
                                                }}
                                    ]
                                        })(
                                            <Input placeholder="Mobile Number" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="Telephone Number"
                                    >
                                        {getFieldDecorator(`tel`, {
                                            // validateTrigger: ['onChange', 'onBlur'],
                                            initialValue: merchantData != undefined ? merchantData.tel : "--",
                                            rules: [{
                                                required: false,
                                                message: "Please enter Telephone Number",
                                            },
                                            {
                                                validator:(rule, value, cb)=>{
                                                    if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)){
                                                      cb('Please enter 10 digit number only')
                                                    }
                                                    cb()
                                                }}
                                    ]
                                        })(
                                            <Input placeholder="Telephone Number" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="GST Number"
                                    >
                                        {getFieldDecorator(`gstNo`, {
                                             initialValue: merchantData != undefined ? merchantData.gstNo : "--",
                                            rules: [{
                                                required: false
                                            }]
                                        })(
                                            <Input placeholder="GST Number" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="PAN Number"
                                    >
                                        {getFieldDecorator(`panNo`, {
                                             initialValue: merchantData != undefined ? merchantData.panNo : "--",
                                            rules: [{
                                                required: false
                                            }]
                                        })(
                                            <Input placeholder="PAN Number" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="Number Of Rooms"
                                    >
                                        {getFieldDecorator(`noOfRooms`, {
                                            initialValue: merchantData != undefined ? merchantData.noOfRooms : "--",
                                            rules: [{
                                                required: true,
                                                message: "Please enter Number Of Rooms",
                                            },
                                            // {
                                            //     validator:(rule, value, cb)=>{
                                            //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 2)){
                                            //           cb('Please enter 2 digit number only')
                                            //         }
                                            //         cb()
                                            //     }}
                                    ]
                                        })(
                                            <Input placeholder="Number Of Rooms" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                             
                                {/* <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="Date of Establishment "
                                    >
                                        {getFieldDecorator(`Date`, {
                                           trigger: 'onBlur',
                                           valuePropName: 'defaultValue',
                                            rules: [{
                                                required: true
                                            }]
                                        })(
                                            <DatePicker format={dateFormat} />
                                        )}
                                    </FormItem>
                                </Col> */}
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem label="Address 1"
                                    >
                                        {getFieldDecorator(`addressLineOne`, {
                                            initialValue: merchantData != undefined ? merchantData.address.addressLineOne : "--",
                                            rules: [{
                                                required: true,
                                                message: "Please enter Address 1",
                                            }]
                                        })(
                                            <Input placeholder="Address 1" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="Address 2"
                                    >
                                        {getFieldDecorator(`addressLineTwo`, {
                                            initialValue: merchantData != undefined ? merchantData.address.addressLineTwo : "--",
                                            rules: [{
                                                required: false,
                                                message: "Please enter Address 2",
                                            }]
                                        })(
                                            <Input placeholder="Address 2" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="City"
                                    >
                                        {getFieldDecorator(`city`, {
                                            initialValue: merchantData != undefined ? merchantData.address.city : "--",
                                            rules: [{
                                                required: true,
                                                message: "Please enter city",
                                            }]
                                        })(
                                            <Input placeholder="City" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="State"
                                    >
                                        {getFieldDecorator(`state`, {
                                            initialValue: merchantData != undefined ? merchantData.address.state : "--",
                                            rules: [{
                                                required: true,
                                                message: "Please enter state",
                                            }]
                                        })(
                                            <Select
                                            optionFilterProp="children"
                                            placeholder="Select State"
                                            showSearch
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                          >
                                            {state.map((c)=>{
                                                return(
                                                  <Option key={c.value} value={c.value} value="disabled" disabled >{c.label}</Option>
                                                )
                                            }) }
                                          </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                                    <FormItem
                                        label="Pincode"
                                    >
                                        {getFieldDecorator(`zip`, {
                                            initialValue: merchantData != undefined ? merchantData.address.zip : "--",
                                            rules: [{
                                                required: true,
                                                message: 'Please enter pincode'
                                            },
                                            {
                                                validator:(rule, value, cb)=>{
                                                    if( value != undefined && value != "" && isNaN(value.trim())){
                                                      cb('Please enter number only')
                                                    }
                                                    cb()
                                                }}
                                            ]
                                        })(
                                            <Input placeholder="Pincode" disabled="true" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
        
        
                           
                            <h3>Bank Details</h3>
                            <br/>
        
                            <Row gutter={16}>
                                <Col xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                                <FormItem label='Bank Name' >
                                        {getFieldDecorator('bankName', {
                                            initialValue: merchantData && merchantData.bankDetails && merchantData.bankDetails.bankName?merchantData.bankDetails.bankName:'',
                                            rules: [{
                                            required: true,
                                            message: "Please enter Bank Name .",
                                            }],
                                        })(
                                            
                                                <Input placeholder="Bank Name" disabled={merchantData && merchantData.bankDetails && merchantData.bankDetails.bankName?true:false}  style={{ width: '100%'}} />
                                        )}
                                </FormItem>
                                <FormItem label='Account Holders Name' >
                                        {getFieldDecorator('accHolder', {
                                            initialValue: merchantData && merchantData.bankDetails && merchantData.bankDetails.accHolder?merchantData.bankDetails.accHolder:'',
                                            rules: [{
                                                //:true,
                                                required: true,
                                                message: "Please enter Account Holders Name.",
                                            }],
                                        })(
                                            
                                                <Input placeholder="Account Holders Name" disabled={merchantData && merchantData.bankDetails && merchantData.bankDetails.accHolder?true:false}  style={{ width: '100%'}} />
                                        )}
                                </FormItem>
                                <FormItem label='Branch Name' >
                                        {getFieldDecorator('branchName', {
                                            initialValue:merchantData && merchantData.bankDetails && merchantData.bankDetails.branchName?merchantData.bankDetails.branchName:'',
                                            rules: [{
                                                //:true,
                                                required: true,
                                            message: "Please enter Branch Name.",
                                            }],
                                        })(
                                            
                                                <Input placeholder="Branch Name" disabled={merchantData && merchantData.bankDetails && merchantData.bankDetails.branchName?true:false}   style={{ width: '100%'}} />
                                        )}
                                </FormItem>
                                </Col>
                                <Col xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}} xl={{span:12}}>
                                <FormItem label='Account Number' >
                                        {getFieldDecorator('accNo', {
                                            initialValue: merchantData && merchantData.bankDetails && merchantData.bankDetails.accNo?merchantData.bankDetails.accNo:'',
                                            rules: [{
                                                //:true,
                                                required: true,
                                            message: "Please enter Account Number.",
                                            }],
                                        })(
                                            
                                                <Input placeholder="Account Number" disabled={merchantData && merchantData.bankDetails && merchantData.bankDetails.accNo?true:false} style={{ width: '100%' }} pattern="^\d{10-18}$" />
                                        )}
                                </FormItem>
                                <FormItem label='IFSC Code' >
                                        {getFieldDecorator('ifscCode', {
                                            initialValue:merchantData && merchantData.bankDetails && merchantData.bankDetails.ifscCode?merchantData.bankDetails.ifscCode:'',
                                            rules: [{
                                                //:true,
                                                required: true,
                                            message: "Please enter IFSC Code'.",
                                            }],
                                        })(
                                            
                                                <Input placeholder="IFSC Code" disabled={merchantData && merchantData.bankDetails && merchantData.bankDetails.ifscCode?true:false}  style={{ width: '100%' }} />
                                        )}
                                </FormItem>
                                </Col>
                                </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Button loading={this.state.loading} type="primary" htmlType="submit"  style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                        Submit
                                    </Button>
                                    <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }}   >Cancel</Button>
                                </Col>    
                            </Row>
        
        
                        </Form>
                            );
                     
                   }
                
                    
                 }}
             
                 </Query>
                   

                
            
                           
                                </App>
        )
    }
}

MerchantProfile.contextType = MyContext
const WrappedMerchantProfile = Form.create()(MerchantProfile);

// const CreateMerchantcompres =  compose(
//     graphql(getMerchantByMob
//             )
//   )(WrappedMerchantProfile)
  
export default withApollo(WrappedMerchantProfile)
