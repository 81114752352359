import React from 'react'
import { Icon, Button, Spin, Breadcrumb, Row,Col, Select, DatePicker, Popconfirm, Form, Input,message } from 'antd';
import App from '../App'

var toInt  = time => ((h,m) => h * 1 + m)(...time.split(':').map(parseFloat)),
    toTime = int => [Math.floor(int/1), int%1 ? '30' : '00'].join(':'),
    range  = (from, to) => 
    Array(to-from+1)
    .fill()
    .map((_,i) => from + i),
    eachHalfHour = (t1, t2) => range(...[t1, t2].map(toInt)).map(toTime);
const { RangePicker} = DatePicker;
const { Option } = Select;
const FormItem = Form.Item


class ManageRooms extends React.Component{
    constructor(props){
        super(props)
        this.state={
            disable: false,
            selected:[]

        }
    }
    handleclick = (i) => {
        console.log('clicked',i);
        let arr =this.state.selected
        arr.push(i) 
        this.setState({selected:arr, disable: true})
        
      }
    onChange = (value) => {
        console.log(`selected ${value}`);
      }
    onSearch = (val) => {
        console.log('search:', val);
      }
      
    render(){
        var hourIntervals = eachHalfHour('01:00','24:00');
console.log(hourIntervals)
const ListItam = hourIntervals.map((hourIntervals,i) =>
<Col lg={{span:2}} xs={{span:6}}><Button onClick={()=>{ this.handleclick(i)}} style={{ marginTop: 10 }} disabled={this.state.selected.indexOf(i) != -1 ? true : false}>{hourIntervals }</Button></Col>

);
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return(
            <App header="Manage Rooms">
                <Form onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Select Room">
                                {getFieldDecorator(`property`, {
                                    rules: [{
                                        required: true,
                                        message: "Please Select Property",
                                    },
                                ]
                                })(
                                    <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Select a room"
                                            optionFilterProp="children"
                                            onChange={this.onChange}
                                            onSearch={this.onSearch}
                                            filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                                <Option value="101">101</Option>
                                                <Option value="102">102</Option>
                                                <Option value="103">103</Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Select Date or Date Range"
                            >
                                {getFieldDecorator(`Date`, {
                                   
                                    rules: [{
                                        required: true
                                    }]
                                })(
                                    <RangePicker />
                                )}
                            </FormItem>   
                        </Col>

                </Row>
                <div>
                <h4>Please select slots to make it unavailable</h4>
        <Row gutter={24} style={{ marginTop: 10 }} type="flex">{ListItam}</Row>
          </div>
         
          <Row gutter={16}>
            
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginTop: "15px",marginBottom:"5%",marginRight:"20px" }} >
                                Save
                            </Button>
                            <Button type="default" style={{background:"#f44336",marginTop: "15px", color:"#fff",marginBottom:"5%" }}  onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                </Form>
            </App>
        )
    }
}
const WrappedManageRooms = Form.create()(ManageRooms);
export default WrappedManageRooms
