import gql from 'graphql-tag'
export default gql`
query getMerchantByMob( $mob: String!){
    getMerchantByMob(mob: $mob){
      id
        mob
        fName
        lName
        hotelName
        email
        tel
        gstNo
        noOfRooms
        status
        isReady
        refId
        createdAt
        propertyType
        panNo
        address{
          addressLineOne
          addressLineTwo
          city
          state
          zip
          country
        }
        bankDetails{
	        bankName
	        accNo
	        ifscCode
	        accHolder
	        branchName
       }
    }
  }`