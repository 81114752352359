import gql from 'graphql-tag'
export default gql`
mutation updateRoomCategory(
  $propertyId: String!
  $roomCategory: String!
  $baseRate1: Float
  $baseRate2: Float
  $additionalRate1: Float
  $additionalRate2: Float
  $guestRate1: Float
  $guestRate2: Float
  $size: String
  $occupancy: Int
){
  updateRoomCategory(input:{
    propertyId: $propertyId
    roomCategory: $roomCategory
    baseRate1: $baseRate1
    baseRate2: $baseRate2
    additionalRate1: $additionalRate1
    additionalRate2: $additionalRate2
    guestRate1: $guestRate1
    guestRate2: $guestRate2
    size: $size
    occupancy: $occupancy
  }){
    id
    property
    propertyId
    roomCategory
    baseRate1
    baseRate2
    additionalRate1
    additionalRate2
    guestRate1
    guestRate2
    size
    occupancy
  }
}`

//   {
    // "propertyId": "test",
    // "roomCategory": "default",
    // "baseRate1": 100,
    // "baseRate2": 200,
    // "additionalRate1": 100,
    // "additionalRate2": 150,
    // "guestRate1": 100,
    // "guestRate2": 100,
    // "size": "50sqft",
    // "occupancy": 2
//   }


