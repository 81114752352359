import React, { Component } from 'react'
// import {Button, Segment, Container, Icon, Responsive, Modal, Header} from 'semantic-ui-react'
import {Button,Icon,Modal,message,Row,Col} from 'antd';
// import { SemanticToastContainer, toast } from 'react-semantic-toasts';
// import GmapDragDrop from 'gmap-dragdrop-react'
import {  debounce } from 'throttle-debounce';

const { compose, withProps, lifecycle } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} = require("react-google-maps");
var _ = require('lodash');

const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");

const MapWithASearchBox = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDc0emFgmpnyDWc-tEe3B4VqkQiBqXuxO8&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),

  lifecycle({

    componentWillMount() {
      const refs = {}
    
      // console.log('in map addr :',this.props.businessAddress)
      if(this.props.lat != ''){
        var position1 = {lat: this.props.lat, lng: this.props.lng}

      }
      this.setState( {
        bounds: null,  
        open: false,
        center: {
          lat: this.props.lat ? this.props.lat: 18.949915, lng: this.props.lng ? this.props.lng : 72.830578,
        },
        position: '',
        address : this.props.businessAddress,
        markers: this.props.lat != '' ? position1 : '',
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: debounce(
          () => {
          this.setState({
          bounds: refs.map.getBounds(),
          center: refs.map.getCenter()
          })
          let { onBoundsChange } = this.props
          if (onBoundsChange) {
          onBoundsChange(refs.map)
          }
          },
          100,
          { maxWait: 500 }
          ),
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },

        onPlacesChanged: () => {
            const places = refs.searchBox.getPlaces();
            const bounds = new window.google.maps.LatLngBounds();

            // console.log('lat :',places[0].geometry.location.lat())
            // console.log('lng :',places[0].geometry.location.lng())
            // this.props.setMarkerLatLng(places[0].geometry.location)
            let position = {lat: places[0].geometry.location.lat(), lng: places[0].geometry.location.lng()}
            places.forEach(place => {
              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport)
              } else {
                bounds.extend(place.geometry.location)
              }
            });
            const nextMarkers = places.map(place => ({
              position: place.geometry.location,
            }));
            const nextCenter = _.get(nextMarkers, '0.position', this.state.center);       
          this.setState({
            center: nextCenter,
            markers: position,
            // open: !this.state.open
          });
        },

        onClickMap: (e) => {
          let markerData = [];
          let position = {lat: e.latLng.lat(), lng: e.latLng.lng()}
          this.setState({
            markers:position,
            position: e.latLng,
            open: !this.state.open
          })

          // this.state.returnOpenValue()

        },
        onsearchBox(){
          this.state.handleInputChange()
          // console
        },
      
        returnOpenValue : ()=> {
          // console.log('In return open-1',this.state.open)

          return this.state.open;
        },
        onSave : ()=>{
          // console.log('In save')
          // this.props.parentHandleClose();
          this.state.onCancelFun()
          this.props.setMarkerLatLng(this.state.position) ;
          let myColor = { background: '#0E1717', text: "#FFFFFF" };
          message.success("Your location has been saved...",3);
        },
      
        onCancelFun : ()=>{

          this.setState({open: !this.state.open})

        }
      })
    },
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    ref={props.onMapMounted}
    zoom={17}
    center={props.center}
    onBoundsChanged={props.onBoundsChanged}
    onClick={props.onClickMap}
  >
 <Modal 
  iconType='save'
  title='Save location'
  footer={null}
  visible={props.returnOpenValue()} 
  onCancel={props.onCancelFun} >
   <p>
    <Button style={ {color:'red'}}  icon="close" ghost onClick = {props.onCancelFun} >
     No
      </Button>
      <Button style={ {color:'green',float:'left'}} icon="check" ghost onClick= {props.onSave}>
        Yes
      </Button>
    </p>
  </Modal>
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
  
      <input
        type="text"
        placeholder="Enter Your Address"
        autoComplete='true'
        icon='user' 
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `255px`,
          height: `40px`,
          marginTop: `55px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
          marginLeft:'-177px'
        }}
      />
 
    </SearchBox>
    
    { props.markers != '' && <Marker  position={props.markers}/>}
  

  </GoogleMap>
);

class MapSearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
        
        };
        this.getMarkerLatLng = this.getMarkerLatLng.bind();
        // this.onClickMap=this.onClickMap.bind();
        if (props.onClickMap){
          props.onClickMap(this.getMarkerLatLng.bind(this));
        }
    }
    //  getMaplatlng(){
    //   return this.latLng;
    // }
    getMarkerLatLng(xyz){
      console.log("latlng", xyz.latLng.lat())
      //  this.setState({latlng: xyz.latLng.lat  ()})
      //  return this.latlng
    }

    render(){
      // console.log("test1",this.props.lat)
      // console.log("test1",this.props.lng)

      return(
            <div>
                <MapWithASearchBox
                  onClickMap={this.getMarkerLatLng}
                  setAddress={this.props.getAddress}
                  setMarkerLatLng={this.props.getLatLong}
                  businessAddress = {this.props.businessAddress}
                  parentHandleClose = {this.props.handleCloseX}
                  lat = {this.props.lat}
                  lng = {this.props.lng}

                />

                {/* <customerForm  
                        getMarkerLatLng = {this.getMarkerLatLng}
                        onClickMap={this.getMarkerLatLng}
                /> */}

            </div>
            )
    }
}

export default MapSearchBox;