import gql from 'graphql-tag';
export default gql`
  query getSlotsByBatchIds($roomIds: [String!], $dateParam: String!) {
    getSlotsByBatchIds(roomIds: $roomIds, dateParam: $dateParam) {
      roomId
      slotDate
      slotsList {
        time
        available
      }
      epochDate
      enabled
      createdAt
    }
  }
`;