import React from 'react'
import { Link } from 'react-router-dom';
import { Icon, Button, Spin, Breadcrumb, Row,Col, Select, DatePicker, InputNumber, Form, Input,message } from 'antd';
import {compose, graphql, withApollo } from 'react-apollo'
import moment from 'moment';
import createSlot from '../mutation/createSlot'
import getSlotByDate from '../query/getSlotByDate'
import App from '../App'
import Updateslot from './updateSlot'

var toInt  = time => ((h,m) => h * 1 + m)(...time.split(':').map(parseFloat)),
    toTime = int => [Math.floor(int/1), int%1 ? '30' : '00'].join(':'),
    range  = (from, to) => 
    Array(to-from+1)
    .fill()
    .map((_,i) => from + i),
    eachHalfHour = (t1, t2) => range(...[t1, t2].map(toInt)).map(toTime);
const { Option } = Select;
const FormItem = Form.Item
const dateFormat = 'DD-MM-YYYY';
function onChange(date, dateString) {
    console.log(date);
  }

class Modalview extends React.Component{
    constructor(props){
        super(props)
        this.state={
            disable: false,
            selected:[],
            time: [] ,
            date: null,
            epochDate:'',
            cSlotData: undefined
            
        }
    }
    onChangedate = (value,value1)=> {
        console.log(`selected ${value1}`);
        var a = moment(value).format("DD-MM-YYYY");
        var b = moment().unix();
        // var a = value1;
        // var b = moment.unix(value/1000)
        // var c = moment(value).getTime()/1000
        // a = moment.format("YYYY/MM/DD")
   this.getslot(a)
    this.setState({
      date:a,
      epochDate:b
    });
 
      
         
      }
    handleclick = (i) => {
        console.log('clicked',i);
        let arr =this.state.selected
        arr.push(i) 
        this.setState({selected:arr, disable: true})
        
      }
      handleclick1 =()=>{
        this.props.history.push('/roomslist')
    }
    onChange = (value) => {
        console.log(`selected ${value}`);
      }
    onSearch = (val) => {
        console.log('search:', val);
      }
      handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log("form data :: ", values)
          if (!err) {
            let finalArr = values.slots

            this.state.selected.map( i => finalArr[i].available = false)

            console.log("FinalArr :: ", finalArr)
            console.log("jdgs",values.slots)
            this.props.client.mutate({
                mutation: createSlot,
                variables: {
                    roomId: this.props.location.state.roomdata.roomId,
                    slotDate: this.state.date,
                    slotsList: finalArr,
                    epochDate: this.state.epochDate,
                    enabled: 'true'
                    }
               
            }).then((data) => {
                message.success('Slot Created sucessfully');
                console.log("success",data)
          
                })
                .catch(err => {
                  message.error('Slots already Created');
                  console.log(`Error ${JSON.stringify(err)}`)
              })

            
          }
        });
      };
      getslot=(a) => {
        // console.log("get slot fuction",a);
        this.props.client.query({
          query:getSlotByDate,
          variables:{ roomId: this.props.location.state.roomdata.roomId, 
                      slotDate: a,
                         },
          fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            console.log("Slots data",data)
            console.log("ago replace",this.state.cSlotData)
            this.setState({
              cSlotData: data.getSlotByDate,
            });
          })
          .catch(err => {
              console.log(`Error ${JSON.stringify(err)}`)
          })
      }
    render(){
        console.log("room data",this.props.location.state.roomdata.roomId)
        console.log("cSlotData",this.state.cSlotData)
        console.log("date ::", this.state.date )
        var cSlotData = this.state.cSlotData
        console.log("cSlotData ::", cSlotData )
        var s = this.state.time
        const { getFieldDecorator, getFieldValue,} = this.props.form;
        var dtime = this.state.selected
        // var date1=this.props.date1
        // var dtime = this.state.selected
        // console.log("time",this.state.selected);
      var hourIntervals = eachHalfHour('00:00','23:00');
      var bbb = hourIntervals
      
      getFieldDecorator('keys', { initialValue: [] });
       const keys = getFieldValue('keys');
       const varhour =keys.concat(bbb)
       const hourIntr =  s.concat(varhour)
       
       var aaa = dtime.map((val) =>{
        bbb.splice(val, 1)
      });
      const ListItam = hourIntr.map((hourIntervals,i) =>
      // console.log("asiuiu",hourIntervals)
      <>
      <Col span={3}>
        <div style={{ display: "flex"}}>
        <FormItem>
        {getFieldDecorator(`slots[${i}]time`, {
          initialValue: hourIntervals,
                  rules: [
                    // {
                    //   type: 'email',
                    //   message: 'The input is not valid E-mail!',
                    // },
                    // {
                    //   required: true,
                    //   message: 'Please input your E-mail!',
                    // },
                  ],
                })(<Col md={{span:1}}><Button value={hourIntervals} onClick={()=>{ this.handleclick(i)}} style={{ marginTop: "5px" }} disabled={this.state.selected.indexOf(i) != -1 ? true : false}>{hourIntervals}</Button></Col>)}
                </FormItem>
                {/* </Col> */}
                {/* <Col span={2}> */}
        <FormItem>
        {getFieldDecorator(`slots[${i}]available`, {
          initialValue: true,
                  // rules: [
                  //   {
                  //     type: 'email',
                  //     message: 'The input is not valid E-mail!',
                  //   },
                  //   {
                  //     required: true,
                  //     message: 'Please input your E-mail!',
                  //   },
                  // ],
                })}
          </FormItem>
          </div>
        </Col>
      
      </>
      );
        return(
            <App header="Manage Rooms">
            <Form onSubmit={this.handleSubmit}>
            <Row gutter={16}>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                        <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                        <Link to="/roomslist">
                            <Breadcrumb.Item>Manage Rooms</Breadcrumb.Item>
                        </Link>
                        </Breadcrumb>
                    </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}}>
                            <Link to={{pathname:"/cratebulkslot",
                            state:{roomId:this.props.location.state.roomdata.roomId}}}
                            >
                                <Button type="default" style={{ marginBottom: 16, float: "right", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                                    Create Slots In Bulk
                                </Button>
                            </Link>
                    </Col>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                    <FormItem
                            label="Select Date"
                        >
                            {getFieldDecorator(`Date`, {
                                rules: [{
                                    required: true
                                }]
                            })(
                                <DatePicker onChange={this.onChangedate}
                                format={dateFormat} />
                            )}
                        </FormItem>   
                    </Col>

            </Row>
                            {cSlotData != null && cSlotData !=undefined ? 
                            <Updateslot slotdata={cSlotData} date={this.state.date} epochDate={this.state.epochDate} roomId={this.props.location.state.roomdata.roomId} />
                            
                            :<>{this.state.date !== null && this.state.date  ?
                              <>       
                              <div>
                                {console.log("in it",this.state.date,cSlotData)}
                                   <h4>Please select slots to make it unavailable</h4>
                                         <Row gutter={24} style={{ marginTop: 10 }} type="flex">{ListItam}</Row>
                               </div>
                               <Row gutter={16}>
                                       <Col span={24}>
                                           <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginTop: "15px",marginBottom:"5%",marginRight:"20px" }} >
                                               Save
                                           </Button>
                                           <Button type="default" style={{background:"#f44336",marginTop: "15px", color:"#fff",marginBottom:"5%" }}  onClick={this.handleclick1} >Cancel</Button>
                                       </Col>    
                                   </Row>
                               </>
                             :''}</>}
            {/* {cSlotData != null && cSlotData !=undefined?
            <Updateslot slotdata={cSlotData} date={this.state.date} epochDate={this.state.epochDate} roomId={this.props.location.state.roomdata.roomId} />
       :  <>
       {this.state.date != null ?  
       <>       
       <div>
         {console.log("in it",this.state.date,cSlotData)}
            <h4>Please select slots to make it unavailable</h4>
                  <Row gutter={24} style={{ marginTop: 10 }} type="flex">{ListItam}</Row>
        </div>
        <Row gutter={16}>
                <Col span={24}>
                    <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginTop: "15px",marginBottom:"5%",marginRight:"20px" }} >
                        Save
                    </Button>
                    <Button type="default" style={{background:"#f44336",marginTop: "15px", color:"#fff",marginBottom:"5%" }}  onClick={this.handleclick1} >Cancel</Button>
                </Col>    
            </Row>
        </>

      : ' '}  
        
      </> 
     } */}
           
            </Form>
            </App>
        )
    }
}
const WrappedModalview = Form.create()(Modalview);
const ModalviewQ = compose(
  graphql(getSlotByDate, {
    name: "getSlotByDate",
    fetchPolicy: 'network-only',
  }),
 
)(WrappedModalview);
export default withApollo(ModalviewQ)