import React from 'react'
import { Icon, Button, Spin, Breadcrumb, Row,Col, Table, Divider, Popconfirm, Form, Tabs,message,Modal } from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import App from '../App'
import getBookingByStatusByProperty from '../query/getBookingByStatusByProperty';
import BookingDeComp from './bookingDeComp'
const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
  }
  
class Checkout extends React.Component{
    constructor(props){
        super(props)
        this.state={
          loading:false,
          bookingData:[],
          dataSource:[],
          visibleNew:false,
          bookingD:[],

        }
    }

    onSelect = (record) => {
      console.log("record", record)
      this.setState({
        bookingD: record,
        visibleNew: true
      })
    }
    handleOk = e => {
      console.log(e);
      this.setState({
        visibleNew: false,
      });
    };
  
    handleCancel = e => {
      console.log(e);
      this.setState({
        visibleNew: false,
      });
    };

  

    enterLoading = ()=>{
      this.setState({
          loading:true
      })
  }
  componentDidMount(){
    this.enterLoading()
    var propertyId = localStorage.getItem('propertyId')
      this.props.client.query({
          query:getBookingByStatusByProperty,
          variables:{ propertyId: propertyId,
                      status: "CHECK_OUT",
          },
          fetchPolicy: 'network-only' ,
          })
          .then(({ data }) => {
            console.log("booking data",data)
            this.createDatasource(data.getBookingByStatusByProperty)
             this.setState({
              bookingData: data.getBookingByStatusByProperty,
              loading:false
            });
          })
          .catch(err => {
              console.log(`Error ${JSON.stringify(err)}`)
          })
      }
      createDatasource=(data)=>{
        let dataSource = data.map((m,i)=>{
          return{
            key:i,
            id: m.id,
            bookingDate: m.bookingDate,
            bookingSlot: m.bookingSlot,
            totalAmt: m.totalAmt,
            name:m.name,
            userId:m.userId,
            noOfGuests:m.noOfGuests,
            Inslot: m.bookingDate +' '+ m.bookingSlot[0],
            outSlot: m.bookingDate +' '+m.bookingSlot.slice(-1)[0],
            email:m.email,
            createdAt:m.createdAt,
            extendedAmt:m.extendedAmt,
            grandTotal:m.grandTotal,
            couponAmt:m.couponAmt,
            couponCode:m.couponCode,
            noOfRooms:m.noOfRooms,
            extendedSlots:m.extendedSlots,
            idNumber:m.idNumber,
            idType:m.idType,
            paymentState:m.paymentState,
            roomId:  m.roomId,
          }
        })
        this.setState({
          dataSource:dataSource
        })
    }
 
    render(){
      console.log("propertyId",this.props.refId)
      console.log("booking data ren",this.state.bookingData)
        const columns = [
            {
                title: 'Booking ID',
                width: 80,
                dataIndex: 'id',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              },{
                title: 'Name',
                width: 125,
                dataIndex: 'name',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              },{
                title: 'Mobile',
                width: 125,
                dataIndex: 'userId',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              },
            {
                title: 'Check-In Time',
                width: 150,
                dataIndex: 'Inslot',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              },{
                title: 'Check-Out Time',
                width: 150,
                dataIndex: 'outSlot',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              }, 
            //   {
            //   title: 'Time',
            //   dataIndex: 'timeSlot',
            //   width: 125,
            //   key: '',
            //   // ...this.getColumnSearchProps('fName'),
            // }, 
            {
              title: 'Number of Guests',
              width: 130,
              dataIndex: 'noOfGuests',
              key: '',
              // ...this.getColumnSearchProps('lName'),
          
            },
               {
              title: 'Amount',
              dataIndex: 'grandTotal',
              width: 125,
              key: '',
              // ...this.getColumnSearchProps('fName'),
            }, 
            // {
            //     title: 'Status',
            //     dataIndex : 'status',

            // }, 
        //     {
        //         title: 'Action',
        //         key: 'action',
        //         width: 150,
        //         render: (data) => {
        //                 return (
        //                     <span>
        //                         {/* <Link to={{pathname:"/checkIn",
        //                             state:{data}
        //                         }}> */}
        //                             {/* <Button icon="edit" type="primary" style={{marginRight:"10px",ursor: "pointer", background:"##f44336 "}}>Edit </Button> */}
        //                             {/* <Button icon="check" type="danger" style={{marginRight:"10px",ursor: "pointer", background:"##f44336 "}}>Check Out</Button> */}
        //                             {/* <Button type="primary" icon="edit" style={{ cursor: "pointer",marginRight:"10px" }}/>
        //                             <Button type="danger" icon="close" style={{ cursor: "pointer",marginRight:"10px" }}/> */}
        //                         {/* </Link> */}
        //                         {/* <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data.mob,data.userName)}>
        //                             <Button icon="delete" type= "danger" style={{marginLeft:"5px",ursor: "pointer", background:"#ff0000 ", color:"#fff"}}/>
        //                         </Popconfirm> */}
        //                     </span>
        //                 )
        //             }
                
        // }
             ];
            //  const data =[
            //      {
            //         id: '1001',
            //         name: 'Ajay', 
            //         inDate:'10-10-2019 09:00',
            //         outDate:'11-10-2019 11:00',
            //         mob:'7350099005',
            //         services: '101',
            //         status:'Pending',
            //         amount:'500'
            //         // timeSlot: '09:00 PM'

            //      }
            //  ]
        return(
          <>
            <Table
             onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  this.onSelect(record)
                }
              };
            }}
             columns={columns} 
             loading={this.state.loading}
              dataSource={this.state.dataSource} 
              pagination={{ pageSize: 10 }} bordered 
              />

            <Modal
            title="Booking Details"
            visible={this.state.visibleNew}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={false}
            width={800}
          >
           <BookingDeComp data={this.state.bookingD}/>
  
          </Modal>
          </>
            )
    }
}
const CheckoutQ = compose(
  graphql(getBookingByStatusByProperty, {
    name: "getBookingByStatusByProperty"
  }),
 
)(Checkout);
export default withApollo(CheckoutQ)