import gql from 'graphql-tag'
export default gql`
mutation updateOnBookingExtended( $id: String! $extendedSlots: [String!] $extendedAmt: Float!){
  updateOnBookingExtended(id: $id extendedSlots: $extendedSlots extendedAmt: $extendedAmt){
    id
    totalAmt
    extendedAmt
    extendedSlots
    bookingSlot
  }
}`
//  {
//        "id": "HS-48",
//        "extendedSlots": ["18:00"],
//        "extendedAmt": 100
//      }