import gql from 'graphql-tag'
export default gql`
mutation updateProperty(
  $propertyId: String!
  $slugHotelName: String
  $hotelName: String
  $noOfRooms: Int
  $description: String
  $amenities: [String!]
  $cleaningTime: CleaningTimeInput
  $mainImage: S3ObjectInput
  $additionalImages: [S3ObjectInput!]
  $latlng: LatLngInput
  $discount: Float
  $location: String!
  $addressLineOne: String!
  $addressLineTwo: String
  $city: String!
  $state: String!
  $zip: String!
  $country: COUNTRY_CODE!
  $enabled: Boolean
  $paymentMethod: [PAYMENT_TYPE!]
  $gstNo: String
  $policiesAndRules: [String!]
  $roomCategory: [String!]
){
updateProperty(input: {
  propertyId: $propertyId
  slugHotelName: $slugHotelName
  hotelName: $hotelName
  noOfRooms: $noOfRooms
  description: $description
  amenities: $amenities
  cleaningTime: $cleaningTime
  mainImage: $mainImage
  additionalImages: $additionalImages
  latlng: $latlng
  discount: $discount
  location: $location
  addressLineOne: $addressLineOne
  addressLineTwo: $addressLineTwo
  city: $city
  state: $state
  zip: $zip
  country: $country
  enabled: $enabled
  paymentMethod: $paymentMethod
  gstNo: $gstNo
  policiesAndRules:$policiesAndRules
  roomCategory:$roomCategory
}){
    property
    propertyId
    slugHotelName
    hotelName
    noOfRooms
    description
    amenities
    policiesAndRules
    tel
    roomCategory
    cleaningTime{
      type
      value
    }
    additionalImages{
      bucket
        region
        key

    }
    gstNo
    latlng{
      lat
      lng
    }
    discount
    location
    addressLineOne
    addressLineTwo
    city
    state
    zip
    country
    createdAt
    updatedAt
    enabled
    paymentMethod
    merchantId
    }
}

`