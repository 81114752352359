import React from 'react'
import { Breadcrumb, Button, Col, Icon, Input, Row, Spin, Switch, Table } from 'antd'
import Highlighter from 'react-highlight-words';
import moment from 'moment'
import { graphql, compose, withApollo } from 'react-apollo';
import getCustomerReviewsByProperty from '../query/getCustomerReviewsByProperty'
import App from '../App'

const enabled = [{key:0, text:"ACTIVE", value:true}, {key:1, text:"INACTIVE", value:false}]


class Reviews extends React.Component{
    constructor(props){
        super(props)
        this.state={
            nextToken : '',
            Rdata :[],
            loading:false,
            hasMore:true
        }
    }
    enterLoading = ()=>{
      this.setState({
          loading:true
      })
  }

    componentDidMount(){
      this.enterLoading()
      var propertyId = localStorage.getItem('propertyId')
      this.props.client.query({
          query:getCustomerReviewsByProperty,
          variables:{ propertyId: propertyId
          },
         options:{ fetchPolicy: 'cache-and-network' },
          })
          .then(({ data }) => {
            console.log("booking data",data.getCustomerReviewsByProperty.items)
            // this.createDatasource(data.getCustomerReviewsByProperty.items)
             this.setState({
              Rdata: data.getCustomerReviewsByProperty.items,
              loading:false
            });
          })
          .catch(err => {
              console.log(`Error ${JSON.stringify(err)}`)
          })
      }
      createDatasource=(data)=>{
        console.log("r data",data)
        
        let dataSource = data.map((val,i)=>{
          return{
            id:val.id,
            bookingId:val.bookingId,
            custName:val.custName,
            rate:val.rate
          }
          // console.log("val data ",val)
        })
        // dataSource.push(dfg[i])

      console.log("data",dataSource)
        // this.setState({
        //   dataSource:dataSource
        // })
    }

    // getColumnSearchProps = (dataIndex) => ({
    //     filterDropdown: ({
    //       setSelectedKeys, selectedKeys, confirm, clearFilters,
    //     }) => (
    //       <div style={{ padding: 8 }}>
    //         <Input
    //           ref={node => { this.searchInput = node; }}
    //           placeholder={`Search ${dataIndex}`}
    //           value={selectedKeys[0]}
    //           onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //           onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
    //           style={{ width: 188, marginBottom: 8, display: 'block' }}
    //         />
    //         <Button
    //           type="primary"
    //           onClick={() => this.handleSearch(selectedKeys, confirm)}
    //           icon="search"
    //           size="small"
    //           style={{ width: 90, marginRight: 8 }}
    //         >
    //           Search
    //         </Button>
    //         <Button
    //           onClick={() => this.handleReset(clearFilters)}
    //           size="small"
    //           style={{ width: 90 }}
    //         >
    //           Reset
    //         </Button>
    //       </div>
    //     ),
    //     filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    //     onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    //     onFilterDropdownVisibleChange: (visible) => {
    //       if (visible) {
    //         setTimeout(() => this.searchInput.select());
    //       }
    //     },

    //     render: (text) => (
    //       <Highlighter
    //         highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //         searchWords={[this.state.searchText]}
    //         autoEscape
    //         textToHighlight={text.toString()}
    //       />
    //     ),
    // })
    
    // handleSearch = (selectedKeys, confirm, dataIndex) => {
    //     confirm();
    //     this.setState({ 
    //       searchText: selectedKeys[0],
    //       searchedColumn: dataIndex,
    //       });
    //   };
    
    //   handleReset = clearFilters => {
    //     clearFilters();
    //     this.setState({ searchText: '' });
    //   };

      submitMut = (e,val) =>{

        console.log('val',val)
        console.log('e',e)
      } 
      
    render(){
      console.log("ren review",this.state.dataSource)
      const columns = [
        {
            title: 'Booking ID',
            width: 80,
            dataIndex: 'bookingId',
            key: 'id',
            // ...this.getColumnSearchProps('id'),
          },{
            title: 'Name',
            width: 125,
            dataIndex: 'custName',
            key: 'name',
            // ...this.getColumnSearchProps('name'),
          },
          {
            title: 'Comment',
            width: 125,
            dataIndex: 'comment',
            key: 'userId',
   
          },
        {
            title: 'Rating',
            width: 150,
            dataIndex: 'rate',
            key: '',
            // ...this.getColumnSearchProps('mob'),
          },
          // ,{
          //   title: 'Check-Out Time',
          //   width: 150,
          //   dataIndex: 'outSlot',
          //   key: '',
          //   // ...this.getColumnSearchProps('mob'),
          // }, 
        //   {
        //   title: 'Time',
        //   dataIndex: 'timeSlot',
        //   width: 125,
        //   key: '',
        //   // ...this.getColumnSearchProps('fName'),
        // }, 
        // {
        //   title: 'Number of Guests',
        //   width: 130,
        //   dataIndex: 'noOfGuests',
        //   key: '',
        //   // ...this.getColumnSearchProps('lName'),
      
        // },
        // {
        //   title: 'Number of Rooms',
        //   width: 130,
        //   dataIndex: 'noOfRooms',
        //   key: '',
        //   // ...this.getColumnSearchProps('lName'),
      
        // },
        //    {
        //   title: 'Amount',
        //   dataIndex: 'totalAmt',
        //   width: 125,
        //   key: '',
        //   // ...this.getColumnSearchProps('fName'),
        // }, 
        // {
        //     title: 'Status',
        //     dataIndex : 'status',

        // }, 

         ];
        return(
            <App header={"Customer Reviews"}>
                <Table  columns={columns} loading={this.state.loading} dataSource={this.state.Rdata} pagination={{ pageSize: 10 }}  />

            </App>
        )
    }
}
export default withApollo(Reviews)