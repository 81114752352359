import React from 'react'
import { Icon, Button, Spin, Modal, Row,Col, Upload, Select , Tabs, Form, Input,message } from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import App from '../App'
import MapSearchBox from './mapSearchBox'
import RoomsUpdate from './roomsupdate'
import updateProperty from '../mutation/updateProperty'
import getPropertyByMerchantId from '../query/getPropertyByMerchantId'
import getSiteSetting from '../query/getSiteSetting'
import MyMapComponent from './mapComponent'
import shortid from 'shortid'
import {MyContext} from '../main'
import AppSyncConfig from '../configs/AppSy'
const bucket =  AppSyncConfig.bucket;
const region = AppSyncConfig.region;

const { TabPane } = Tabs;
const { Option } = Select;
const FormItem = Form.Item
const { TextArea } = Input;
const state = [
    {value:'JAMMU_KASHMIR',label:'JAMMU KASHMIR'},
    {value:'HIMACHAL_PRADESH',label:'HIMACHAL PRADESH'},
    {value:'PUNJAB',label:'PUNJAB'},
    {value:'DELHI',label:'DELHI'},
    {value:'RAJASTHAN',label:'RAJASTHAN'},
    {value:'UTTARAKHAND',label:'UTTARAKHAND'},
    {value:'UTTAR_PRADESH',label:'UTTAR PRADESH'},
    {value:'WEST_BENGAL',label:'WEST BENGAL'},
    {value:'BIHAR',label:'BIHAR'},
    {value:'JHARKHAND',label:'JHARKHAND'},
    {value:'ODISHA',label:'ODISHA'},
    {value:'ASSAM',label:'ASSAM'},
    {value:'ARUNACHAL_PRADESH',label:'ARUNACHAL PRADESH'},
    {value:'MEGHALAYA',label:'MEGHALAYA'},
    {value:'TRIPURA',label:'TRIPURA'},
    {value:'MIZORAM',label:'MIZORAM'},
    {value:'MANIPUR',label:'MANIPUR'},
    {value:'NAGALAND',label:'NAGALAND'},
    {value:'MAHARASHTRA',label:'MAHARASHTRA'},
    {value:'GUJARAT',label:'GUJARAT'},
    {value:'GOA',label:'GOA'},
    {value:'CHHATTISGARH',label:'CHHATTISGARH'},
    {value:'MADHYA_PRADESH',label:'MADHYA PRADESH'},
    {value:'ANDHRA_PRADESH',label:'ANDHRA PRADESH'},
    {value:'HARYANA',label:'HARYANA'},
    {value:'KERALA',label:'KERALA'},
    {value:'SIKKIM',label:'SIKKIM'},
    {value:'TAMIL_NADU',label:'TAMIL NADU'},
    {value:'TELANGANA',label:'TELANGANA'},
    {value:'KARNATAKA',label:'KARNATAKA'},
]
function onChange(value) {
    console.log('changed', value);
  }
  function callback(key) {
    console.log(key);
  }
class ManageProperty extends React.Component{
    constructor(props){
        super(props)
        this.state={
            position:this.props.location.state != undefined &&
            this.props.location.state!=null && 
             this.props.location.state.latlng != null ? 
              this.props.location.state.latlng:undefined,
            // lat:'',
            // lnh:'',
            // lng: this.props.location.state != undefined && this.props.location.state.exhibitorData!=null &&  this.props.location.state.exhibitorData.latlng != null ?  this.props.location.state.exhibitorData.latlng.lng:'',
            // lat: this.props.location.state != undefined && this.props.location.state.exhibitorData!=null && this.props.location.state.exhibitorData.latlng != null ?  this.props.location.state.exhibitorData.latlng.lat:'',
            loading:false,
            visible:false,
            iconImg: {},
            removedIconImg: false,
            value: true,
            fileList:[],
            facility:{},
            showError:false,
            isError:false,
            locationSaved:null,
            favLogoErr:false,
            locatioError:false
        }
    }
    
    // componentWillReceiveProps(nextProps){
    //     console.log("cwr",nextProps)

    // }

    facility=()=>{
        // console.log("facility")

        this.props.client.query({
            query: getSiteSetting,
            fetchPolicy: 'network-only' 
          }).then(({data})=>{
            // console.log(' facility data',data)
            this.setState({
                facility:data.getSiteSetting,
            })
          }).catch((err)=>{
            console.log(err)
          })

    }
  
      componentDidMount(){
          this.facility()
            var rid = localStorage.getItem('refId')
           this.props.client.query({
                    query:getPropertyByMerchantId,
                    variables:{ merchantId: rid,
                                   },
                    fetchPolicy: 'network-only',
                    })
                    .then(({ data }) => {
                    //   console.log("AjayAjay",data)
                       this.setState({
                        merchantData: data.getPropertyByMerchantId,
                        loading:false
                       },()=>{this.createImg(data.getPropertyByMerchantId)
                       
                      });
                    })
                    .catch(err => {
                        console.log(`Error ${JSON.stringify(err)}`)
                    })
   
        }

        createImg=(data)=>{
            console.log("im fun",data.latlng)
            if(data.latlng!=null && data.latlng!=undefined)
            {
                this.setState({
                    // lat:data.latlng.lat,
                    // lng:data.latlng.lng
                    position:data.latlng
                })
            }

            if(data!=null){
                let img = data.additionalImages != null ?  data.additionalImages :[]
                let newImg=[]
                img.map((val)=>{
                    newImg.push({
                        key:val.key,
                        uid:val.key,
                        bucket:val.bucket,
                        region:val.region,
                        url:`https://${val.bucket}.s3.${val.region}.amazonaws.com/${val.key}`
                    })
                 })
                           this.setState({
                                fileList:newImg,
                              })
             }
            
        }

        enterLoading = ()=>{
            this.setState({
                loading:true,
                // showError:true
            })
        }


    handleSubmit=(e)=>{
        console.log("on submit location",this.state.position)
        e.preventDefault();
        

        this.props.form.validateFields((err, values) => {
            

if(this.state.position==undefined){
    this.setState({
locatioError:true,
    })
    return false
}
else{
    this.setState({
        locatioError:false,
            })
}

          if (!err) {
        this.enterLoading()

           
            console.log('Received values of form: ', values);
            
            if(this.state.fileList.length<=4)
            {
                console.log("in imag")
                message.error('Upload Minimum 5 Images');
                this.setState({
                    // isError:true
                    loading:false
                })
                return false
            }
            // else{
            //     this.setState({
            //         isError:false
            //     })
            // }
           
            // return

            if(this.state.fileList && this.state.fileList.length >=5 && this.state.fileList.length <=15){
                console.log("sam")
                let image=[]
                values.fileList =  this.state.fileList.map((p) =>{
               let k=shortid.generate()

                    if(p && !p.hasOwnProperty('key'))
                    {
                        console.log("sam1")
                    return{
                    bucket: bucket,
                    region: region,
                    localUri:p.originFileObj,
                    key:`hourstay/Property/${this.state.merchantData.slugHotelName}/Images/${k}`,
                    mimeType:p.type
                    }
                    
                    }
                else{
                    return{
                    bucket:bucket,
                    region:region,
                    key:p.key
                    }
                }
            })
                  image.push(values.fileList) 
                  console.log("values.fileList",values.fileList)

              }
              else{
               
            this.setState({
                loading:false
            })
         
              }
            console.log("in mut")
            try{

           
            this.props.client.mutate({
                mutation: updateProperty,
                variables: {
                    propertyId:this.state.merchantData.propertyId,
                    slugHotelName:this.state.merchantData.slugHotelName,
                    hotelName:this.state.merchantData.hotelName,
                    noOfRooms:this.state.merchantData.noOfRooms,
                    roomCategory:["default"],
                    additionalImages:values.fileList!=undefined && values.fileList!=null ? values.fileList:undefined,
                    amenities:values.amenities,
                    policiesAndRules:values.policiesAndRules,
                    description:values.description,
                    cleaningTime:{
                      type:"HOURS",
                      value:values.value,
                    },
                    gstNo:this.state.merchantData.gstNo,
                    latlng:
                    {
                      lat:this.state.position.lat!=undefined && this.state.position.lat!=null ? this.state.position.lat:undefined,
                      lng:this.state.position.lng!=undefined && this.state.position.lng!=null ? this.state.position.lng:undefined,
                    },
                    discount:values.discount,
                    location:this.state.merchantData.location,
                    addressLineOne:values.addressLineOne,
                    addressLineTwo:values.addressLineTwo ? values.addressLineTwo :null,
                    city:this.state.merchantData.city,
                    state:values.state,
                    zip:values.zip,
                    country:"IND",
                    enabled:true,
                    paymentMethod:values.paymentMethod,
                    
                }
                }).then((data) => {
                    this.setState({
                        loading:false
                    })
                    message.success('Property Updated Successful');
                    console.log(data)
                    })
                    .catch(err =>{
                        console.log('in catch :: ',err)
                        
                    });
                }
                catch(err){
                    console.log("err",err)
                }
          }

        });
    }

    siteHandleChange1 = ( {fileList} ) => {
        console.log("fileList",fileList);
        this.setState({
            fileList:fileList
        })
        
      }

      

  
    handleOpen = () => this.setState({ visible: !this.state.visible })


    handleCancel = () => this.setState({visible:false})

    handleclick =()=>{
        this.props.history.push('/manage-booking')
    }

    beforeUpload = (fileList) =>{
        console.log("before upload ")
        var fr = new FileReader;

        fr.onload = () => { // file is loaded

            var img = new Image;
            img.onload = () => {
                
                if (img.width != 640 && img.height != 430) {

                  this.setState({
                    favLogoErr:true
                  })
                  this.props.form.setFields({
                    
                    additionalImages:{
                      value:this.state.additionalImages,
                      errors:[new Error('Please Upload image with (640 px * 430 px) dimensions.')]
                    }
                  })
                  return false
                }else{
                  this.setState({
                    favLogoErr:false
                  })
                }
              };
            img.src = fr.result; // is the data URL because called with readAsDataURL
        };
        fr.readAsDataURL(fileList); // I'm using a <input type="file"> for demonstrating
      }


      getPosition=(position)=>{
          console.log("pos",position)
        this.setState({
          position:position,
          locationSaved: true,
          locatioError:false
        })
      }


    render(){
        // console.log("location",this.state.position)
        const data = this.state.position?this.state.position : undefined;

        let facilityData=this.state.facility;
        // console.log("policy",this.state.merchantData)
        // console.log("ss",this.state.merchantData?this.state.merchantData:'kk')
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const {fileList } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>Upload</div>
            </div>
            
            ); 
        return(
            <App header="Manage Property">
                <Tabs onChange={callback} type="card">
                <TabPane tab="Property" key="1">
                <Form onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Number Of Rooms"
                            >
                                {getFieldDecorator(`noOfRooms`, {
                                     initialValue:this.state.merchantData!=null && this.state.merchantData.noOfRooms!=null?this.state.merchantData.noOfRooms:'',
                                  
                                     rules: [{
                                        required: true,
                                        message: "Please enter Number Of Rooms",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 2)){
                                    //           cb('Please enter 2 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Number Of Rooms" disabled="true"  style={{ width: '100%' }} />
                                )}
                        </FormItem>
                        </Col>
                        {/* <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Occupancy"
                            >
                                {getFieldDecorator(`occupancy`, {
                                    initialValue:this.state.merchantData!=null && this.state.merchantData.occupancy!=null?this.state.merchantData.occupancy:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Occupancy",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 1)){
                                    //           cb('Please enter 1 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Occupancy" style={{ width: '100%' }} />
                                )}
                        </FormItem>
                        </Col> */}
            
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Description"
                            >
                                {getFieldDecorator(`description`, {
                                    initialValue:this.state.merchantData!=null && this.state.merchantData.description!=null?this.state.merchantData.description:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Description",
                                    },
                                    
                            ]
                                })(
                                    <TextArea  placeholder="Description" style={{ width: '100%' }}
                                    autoSize={{ minRows: 3, maxRows: 5 }} />
                                )}
                        </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Cleaning Time"
                            >
                                {getFieldDecorator(`value`, {
                                    initialValue:this.state.merchantData!=null && this.state.merchantData.cleaningTime!=null && this.state.merchantData.cleaningTime.value!=null ?this.state.merchantData.cleaningTime.value: '',
                                
                                    //  initialValue: this.state.merchantData != undefined ? this.state.merchantData.cleaningTime.value : "--",
                                    rules: [{
                                        required: true,
                                        message: "Please enter Cleaning Time",
                                    },
                                  
                            ]
                                })(
                                    <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Select Cleaning Time"
                                            optionFilterProp="children"
                                            onChange={this.onChange}
                                            onSearch={this.onSearch}
                                            filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                                <Option value="0">0 Hour</Option>
                                                <Option value="1">1 Hour</Option>
                                                {/* <Option value="2">2 Hours</Option> */}
                                                {/* <Option value="1 hours">1 hours</Option> */}
                                    </Select>
                                )}
                        </FormItem>
                        </Col>
                        </Row>
                       <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Amenities"
                            >
                                {getFieldDecorator(`amenities`, {
                                    initialValue:this.state.merchantData!=null && this.state.merchantData.amenities!=null?this.state.merchantData.amenities:undefined,

                                    //  initialValue:facilityData != undefined && facilityData.facilities!=undefined ? facilityData.facilities :'',
                                    rules: [{
                                        required: true,
                                        message: "Please Select Amenities",
                                    },
                            ]
                                })(
                                    // <Select placeholder="Select Amenities" mode="tags" style={{ width: '100%' }}  tokenSeparators={[',']}/>
                                    <Select mode="multiple" placeholder="Select Amenities" style={{width:"100%"}}>
                                    {
                                       facilityData != undefined && facilityData.facilities!=undefined && facilityData.facilities.map((val)=>{
                                        return(
                                          <Option value={val}>{val}</Option>
                                      )
                                     })
                                     }
                                  </Select>
                                )}
                        </FormItem>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >

                            <Form.Item label="Policies And Rules">
                                {getFieldDecorator('policiesAndRules', {
                                    initialValue:this.state.merchantData!=null && this.state.merchantData.policiesAndRules!=null?this.state.merchantData.policiesAndRules: undefined,
                                    // initialValue: facilityData != undefined && facilityData.policiesAndRules != undefined ? facilityData.policiesAndRules : '',
                                    rules: [
                                        { required: true, message: 'Please Select Policies And Rules !', type: 'array' },
                                    ],
                                })(
                                    <Select mode="multiple" placeholder="select Policies And Rules" style={{ width: "100%" }}>
                                        {
                                            facilityData != undefined && facilityData.policiesAndRules != undefined && facilityData.policiesAndRules.map((val) => {
                                                return (
                                                    <Option value={val}>{val}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>

                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Locality"
                            >
                                {getFieldDecorator(`location`, {
                                     initialValue:this.state.merchantData!=null && this.state.merchantData.location!=null?this.state.merchantData.location:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Locality",
                                    },
                            ]
                                })(
                                    <Input placeholder="Locality" disabled='true' style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Address 1"
                            >
                                {getFieldDecorator(`addressLineOne`, {
                                     initialValue:this.state.merchantData!=null && this.state.merchantData.addressLineOne!=null?this.state.merchantData.addressLineOne:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Address 1",
                                    }]
                                })(
                                    <Input placeholder="Address 1" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Address 2"
                            >
                                {getFieldDecorator(`addressLineTwo`, {
                                     initialValue:this.state.merchantData!=null && this.state.merchantData.addressLineTwo!=null?this.state.merchantData.addressLineTwo:undefined,
                                    rules: [{
                                        required: false,
                                        message: "Please enter Address 2",
                                    }]
                                })(
                                    <Input placeholder="Address 2" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="City"
                            >
                                {getFieldDecorator(`city`, {
                                     initialValue:this.state.merchantData!=null && this.state.merchantData.city!=null ? this.state.merchantData.city:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter city",
                                    }]
                                })(
                                    <Input placeholder="City" disabled='true' style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="State"
                            >
                                {getFieldDecorator(`state`, {
                                     initialValue:this.state.merchantData!=null && this.state.merchantData.state!=null?this.state.merchantData.state:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter state",
                                    }]
                                })(
                                //     <Select
                                //     optionFilterProp="children"
                                //     placeholder="Select State"
                                //     showSearch
                                //     filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                //   >
                                //     {state.map((c)=>{
                                //         return(
                                //           <Option key={c.value} value={c.value} value="disabled">{c.label}</Option>
                                //         )
                                //     }) }
                                //   </Select>
                                <Input placeholder="State" disabled='true' style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Pincode"
                            >
                                {getFieldDecorator(`zip`, {
                                     initialValue:this.state.merchantData!=null && this.state.merchantData.zip!=null?this.state.merchantData.zip:'',
                                    rules: [{
                                        required: true,
                                        message: 'Please enter pincode'
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && isNaN(value.trim())){
                                    //           cb('Please enter number only')
                                    //         }
                                    //         cb()
                                    //     }}
                                    ]
                                })(
                                    <Input placeholder="Pincode" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Payment Type"
                            >
                                {getFieldDecorator(`paymentMethod`, {
                                    initialValue:this.state.merchantData!=null && this.state.merchantData.paymentMethod!=null && this.state.merchantData.paymentMethod!= undefined ?this.state.merchantData.paymentMethod: undefined,
                                
                                    //  initialValue: this.state.merchantData != undefined ? this.state.merchantData.paymentMethod : "--",
                                    rules: [{
                                        required: true,
                                        message: "Please Select Payment Type",
                                    },
                                  
                            ]
                                })(
                                    <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Select Payment Type"
                                            optionFilterProp="children"
                                            onChange={this.onChange}
                                            onSearch={this.onSearch}
                                            mode="multiple"
                                            filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                                <Option value="PAH">Pay At Hotel</Option>
                                                <Option value="PAID_ONLINE">Online</Option>
                                                {/* <Option value="2">2 Hours</Option> */}
                                                {/* <Option value="1 hours">1 hours</Option> */}
                                    </Select>
                                )}
                        </FormItem>
                        </Col>

               
</Row>
<Row gutter={16}>
<Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                    <Form.Item label={<span>Additional Images<span style={{color: 'red'}}>(640 px * 430 px)</span></span>}>
                    {getFieldDecorator('additionalImages', {
                     initialValue:this.state.merchantData!=null && this.state.merchantData.additionalImages!=null?this.state.merchantData.additionalImages:'--',

                      rules: [{
                        required: true,
                        message: "Please upload Images"
                      }]
                    })(
                    <Upload
                      listType="picture-card"
                      beforeUpload={this.beforeUpload}
                      onChange={this.siteHandleChange1}
                      multiple={true}
                      fileList={fileList}
                    >
                        {fileList.length >= 15 ? null : uploadButton}
                    {/* {fileList.length > 5 && fileList.length <= 15?  null : uploadButton} */}
                    </Upload>
                    
                    )}
                    {/* {this.state.showError == true ? 'Upload Minimum 5 Images': ''} */}
                {this.state.fileList.length<=4 && <h1 style={{color:'red',fontWeight:'700'}}>*Upload Minimum 5 Images</h1>} 

                  </Form.Item> 

                </Col>


                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
              {/* <FormItem label="Address Pin on map" >
                        {
                            getFieldDecorator('latlng', {
                                // validateTrigger: ['onChange', 'onBlur'],
                                initialValue: {
                                    lat:this.state.lat,
                                    lng:this.state.lng,
                                },
            
                            
                            })(
                                <div>
                                <div  onClick={this.handleOpen} ><img src="https://cdn.pixabay.com/photo/2016/03/22/04/23/map-1272165__340.png" width="40" height="40" /></div>
                            <Modal
                            footer={null}
                             width='170'
                                title="Select your Location on the Map"
                                visible={this.state.visible}
                                onOk={this.handleOpen}
                                onCancel={this.handleCancel}
                                >  <MapSearchBox
                                        handleInputChange={this.handleInputChange}
                                        getLatLong={this.getLatLong}
                                        handleCloseX={this.handleClose}
                                        businessAddress={this.state.businessAddress}
                                        lat={this.state.lat}
                                        lng={this.state.lng}

                                    />
                                
                            </Modal>
                            </div>
                            )
                        }
                    </FormItem>
                    {this.state.lat &&
                    <div style={{color:"green"}}>
                        <b>Location Updated...</b>
                        </div> 
                     }
                  {this.state.lat == '' &&
											this.state.error && (
												<div  style={{ background: 'red' }}>
													<div  style={{padding: '0px',margin: '0px',color: '#fff' }}>
														<p className="header">Please select the Location Pin on Map.</p>
													</div>
												</div>
                                            )} */}
                                    <Form.Item label="Address Pin on map" >
                                        {getFieldDecorator('position', {
                                            // initialValue
                                            rules: [{ required: false, message: 'Please provide location' }],
                                        })(
                                            <>
                                                <div onClick={this.handleOpen} ><img src="https://cdn.pixabay.com/photo/2016/03/22/04/23/map-1272165__340.png" width="40" height="40" /></div>
                                                <Modal 
                                                    footer={null}
                                                    width='170'
                                                    title="Select your Location on the Map"
                                                    visible={this.state.visible}
                                                    onOk={this.handleOpen}
                                                    onCancel={this.handleCancel}
                                                >
                                                    <MyMapComponent isMarkerShown getPosition={this.getPosition} position={data} />
                                                </Modal>
                                            </>
// <Icon type="check" /> Location Updated
                                        )}
                                    </Form.Item>
                                    { this.state.position !== undefined && this.state.locationSaved == null ? <p style={{color:'green'}}><Icon type="check"/> Location Updated</p> : this.state.locationSaved  && <p style={{color:'green'}}><Icon type="check"/> Location Saved</p>}

                                    {/* {this.state.position &&
                    <div style={{color:"green"}}><b>Location Updated...</b></div> 
                  } */}
                  {this.state.position == undefined &&
											this.state.error && (
													<div style={{padding: '0px',margin: '0px' }}>
														<p>Please select the Location Pin on Map.</p>
													</div>
                                            )
                                            }

                                            {this.state.locatioError && <h1 style={{color:'red'}}>Please select the Location Pin on Map</h1>}
              </Col> 

              <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Discount(%)"
                            >
                                {getFieldDecorator(`discount`, {
                                      initialValue:this.state.merchantData!=null && this.state.merchantData.discount!=null? this.state.merchantData.discount:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Discount",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 1)){
                                    //           cb('Please enter 1 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Discount(%)" style={{ width: '100%' }} />
                                )}
                        </FormItem>
                    </Col>
              </Row>
                        {/* <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Photos"  >
                                        {getFieldDecorator('icon', {
                                            // validateTrigger: ['onChange','onBlur'],
                                            rules: [{
                                                required: true,
                                                message: 'Please Upload Image'

                                            }],
                                        })(
                                            <Upload
                                                listType="picture-card"
                                                // data={iconImg}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleMainImage}
                                                onRemove={this.handleMainImageRemove}
                                                fileList={fileList}
                                                multiple={true}
                                            >
                                            {fileList.length >= 10 ? null : uploadButton}
                                            </Upload>
                                        )}
                                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                            </FormItem>
                        </Col> */}
                      {/* {this.state.isError && <h1 style={{color:'red',fontWeight:'700'}}>*Upload Minimum 5 Images</h1>}  */}
                
                <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading}  style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }}  onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                </Row>
                </Form>
                </TabPane>
                <TabPane tab="Rooms" key="2">
                    <RoomsUpdate pro={this.state.merchantData}/>
                    </TabPane>
                </Tabs>
            </App>
        );
    }
}
ManageProperty.contextType = MyContext

const WrappedManageProperty = Form.create()(ManageProperty);
export default withApollo(WrappedManageProperty)

