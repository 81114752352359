import gql from 'graphql-tag'
export default gql`
query getCustomerReviewsByProperty( $propertyId: String!){
  getCustomerReviewsByProperty(propertyId: $propertyId){
    items{
      id
      propertyId
      custId
      bookingId
      custName
      rate
      comment
      createdAt
    }
    nextToken
  }
}
# {
#   "propertyId": "HOTEL-31-taj-hotel"
# }`