import { compose, withProps, lifecycle } from "recompose";
import React, { Component } from 'react';
import SearchBox from 'react-google-maps/lib/components/places/SearchBox'
import _ from 'lodash';
import {
  Form, Icon, Input, Button, Upload, Checkbox, Row, Col, Steps, Select, Divider, Radio, Card, message, DatePicker, Modal
} from 'antd';

const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
  } = require("react-google-maps");

 
  const MapWithASearchBox = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDc0emFgmpnyDWc-tEe3B4VqkQiBqXuxO8&v=3.exp&libraries=geometry,drawing,places&sensor=false",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
      componentWillMount() {
        const refs = {}
  
        this.setState({
          bounds: null,
          saved:false,
          center: {
            lat: 18.5204, lng: 73.8567
            // lat: this.props.position.lat ? this.props.position.lat: 28.7040592, lng: this.props.position.lng ? this.props.position.lng : 77.1024901,
          },
          markers: [],
          onMapMounted: ref => {
            refs.map = ref;
          },
          onBoundsChanged: () => {
            this.setState({
              bounds: refs.map.getBounds(),
              // center: refs.map.getCenter(),
            })
          },
          onSearchBoxMounted: ref => {
            refs.searchBox = ref;
          },
          onPlacesChanged: () => {
            const places = refs.searchBox.getPlaces();
            const bounds = new window.google.maps.LatLngBounds();
            
            places.forEach(place => {
              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport)
              } else {
                bounds.extend(place.geometry.location)
              }
            });
            const nextMarkers = places.map(place => ({
              position: place.geometry.location,
            }));
            const nextCenter = _.get(nextMarkers, '0.position', this.state.center);
          
            this.setState({
              center: nextCenter,
              markers: nextMarkers,
            });
    
          },
          onClickMap: (e) => {
            let marker = []
            marker.push({'position':e.latLng})
            this.setState({
              markers: marker,
              center: e.latLng
            });
          },
          onCancelFun:()=>{
            this.setState({
              modal:false
            });
          },
          onSave:()=>{
            this.setState({
              saved:true
            }) 
          }
        })
      },
    }),
    withScriptjs,
    withGoogleMap
  )(props =>{
console.log(props.position && props.markers.length == 0 ? props.position : props.center)
    return(<GoogleMap
      ref={props.onMapMounted}
      defaultZoom={15}
      center={props.position && props.markers.length == 0 ? props.position : props.center}
      // center={{lat: 28.68627380000001, lng: 77.2217831}}
      onBoundsChanged={props.onBoundsChanged}
      onClick={props.onClickMap}
    >
  
      <Button type="primary" style={ {float:'right'}} 
        onClick= {(e)=>{ 
          let obj = {}
          obj['lat'] = props.center.lat();
          obj['lng'] = props.center.lng();
          props.getPosition(obj)
          message.success("location saved")
          }}
      >
        Save Location
      </Button>
   
      <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Search for location"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            marginTop: `27px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
          onKeyPress={(e)=>{
            if(e.keyCode === 13){
              e.preventDefault(); 
              // return false
            }
          }}
        />
      </SearchBox>

      {props.position  && props.markers.length == 0 ? 
      <Marker key={'marker'} position={props.position} />
      : props.markers.map((marker, index) =>
        <Marker key={index} position={marker.position} />
      )}
    </GoogleMap>
  )
      }
  )
class MapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { modalVisible:false }
    }
    

    onModalOpen=()=>{
      this.setState({
        modalVisible: true
      })
    }

    handleModalOk = (e) => {
      this.setState({
        modalVisible: false,
      });
    }
  
    handleModalCancel = (e) => {
      this.setState({
        modalVisible: false,
      });
    }


    render() { 

        return ( 
          <>
            <MapWithASearchBox getPosition={this.props.getPosition} position={this.props.position}/>
          </>
         );
    }
}


  export default MapComponent;
  