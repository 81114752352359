import React from 'react'
import { Icon, Button, Spin, Modal, Row,Col, Upload, Select , Tabs, Form, Input,message } from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import updateRoomCategory from '../mutation/updateRoomCategory'
const FormItem = Form.Item
class RoomsUpdate extends React.Component{
    constructor(props){
        super(props)
        this.state={
            loading: false,

        }
    }
    handleSubmit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
              console.log("form values",values)
              
              this.props.client.mutate({
                  mutation: updateRoomCategory,
                  variables: {
                      propertyId: this.props.pro.propertyId,
                      roomCategory: "default",
                      occupancy: values.occupancy,
                      description: values.description,
                      baseRate1: values.baseRate1,
                      baseRate2: values.baseRate2,
                      guestRate1: values.guestRate1,
                      guestRate2: values.guestRate2,
                      additionalRate1: values.additionalRate1,
                      additionalRate2: values.additionalRate2,
                      size:values.size
                  }
              }).then((data) => {
                  this.setState({
                      loading: false
                  })
                  message.success('Rooms Updated Successful');
                  console.log(data)
              })
          }
        })
    }
    
    handleclick =()=>{
        this.props.history.push('/manage-property')
    }

    render(){
        const { getFieldDecorator, getFieldValue } = this.props.form;
        console.log("propertyId",this.props.pro.attachedRoomCategories[0])
        var RoomCategory = this.props.pro.attachedRoomCategories[0]
        return(
            <Form onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                  <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Occupancy"
                            >
                                {getFieldDecorator(`occupancy`, {
                                    initialValue:RoomCategory!=null && RoomCategory.occupancy!=null?RoomCategory.occupancy:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Occupancy",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 1)){
                                    //           cb('Please enter 1 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Occupancy" style={{ width: '100%' }} />
                                )}
                        </FormItem>
                    </Col>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Room Size"
                            >
                                {getFieldDecorator(`size`, {
                                      initialValue:RoomCategory!=null && RoomCategory.size!=null? RoomCategory.size:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Room Size",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 1)){
                                    //           cb('Please enter 1 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Room Size (Square Feet)" style={{ width: '100%' }} />
                                )}
                        </FormItem>
                    </Col>
                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Base Rate (2 hours) (week days) "
                            >
                                {getFieldDecorator(`baseRate1`, {
                                     initialValue:RoomCategory!=null && RoomCategory.baseRate1 !=null? RoomCategory.baseRate1:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Rate week days",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length > 3)){
                                    //           cb('Please enter 3 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Week Days Rates" style={{ width: '100%' }} />
                                )}
                        </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <FormItem
                                label="Base Rate (2 hours) (weekend days)"
                            >
                                {getFieldDecorator(`baseRate2`, {
                                     initialValue:RoomCategory!=null && RoomCategory.baseRate2 !=null ? RoomCategory.baseRate2:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Rate weekend days",
                                    },
                                    // {
                                    //     validator:(rule, value, cb)=>{
                                    //         if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length > 3)){
                                    //           cb('Please enter 3 digit number only')
                                    //         }
                                    //         cb()
                                    //     }}
                            ]
                                })(
                                    <Input placeholder="Weekend Days Rates" style={{ width: '100%' }} />
                                )}
                        </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Additional 1 hours Rate (week days)"
                            >
                                {getFieldDecorator(`additionalRate1`, {
                                     initialValue:RoomCategory!=null && RoomCategory.additionalRate1 !=null ? RoomCategory.additionalRate1:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Additional 1 hours Rate (week days)",
                                    }]
                                })(
                                    <Input placeholder="Additional 1 hours Rate (week days)" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Additional 1 hours Rate (weekend days)"
                            >
                                {getFieldDecorator(`additionalRate2`, {
                                     initialValue:RoomCategory!=null && RoomCategory.additionalRate2 !=null ? RoomCategory.additionalRate2:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Additional 1 hours Rate (weekend days)",
                                    }]
                                })(
                                    <Input placeholder="Additional 1 hours Rate (weekend days)" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Guest Rate (week days)"
                            >
                                {getFieldDecorator(`guestRate1`, {
                                    initialValue: RoomCategory != undefined ? RoomCategory.guestRate1 : '',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Guest Rate (week days)",
                                    }]
                                })(
                                    <Input placeholder="Guest Rate (week days)" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Guest Rate (weekend days)"
                            >
                                {getFieldDecorator(`guestRate2`, {
                                    initialValue: RoomCategory != undefined ? RoomCategory.guestRate2 : '',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Guest Rate (weekend days)",
                                    }]
                                })(
                                    <Input placeholder="Guest Rate (weekend days)" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }}   >Cancel</Button>
                        </Col>    
                </Row>
            </Form>
        )
    }
}
const WrappedRoomsUpdate = Form.create()(RoomsUpdate);
export default withApollo(WrappedRoomsUpdate)