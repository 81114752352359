import gql from 'graphql-tag'
export default gql`
query getPropertyByMerchantId( $merchantId: String!){
  getPropertyByMerchantId( merchantId: $merchantId){
      property
      propertyId
     

      
      # attachedRooms{
      #   id
      #   propertyId
      #   roomId
      #   roomNo
      #   merchantId
      #   enabled
      #   createdAt
      #   roomSlots{
      #     id
      #     roomId
      #     slotDate
      #     epochDate
      #     slotsList{
      #       time
      #       available
      #     }
      #     enabled
      #     createdAt
      #   }
        
      # }
  }
}
`