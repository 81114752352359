import gql from 'graphql-tag'
export default gql`
  mutation updateMerchant(
  $mob: String!
  $fName: String!
  $lName: String!
  $hotelName: String
  $email: String
  $tel: String
  $gstNo: String
  $noOfRooms: Int
  $address: GenericAddressInput
  $status: STATUS
  $isReady: Boolean
  $enabled: Boolean
  $panNo: String
  $bankDetails: BankDetailsInput

){
  updateMerchant(input:{
    mob: $mob
    fName: $fName
    lName: $lName
    hotelName: $hotelName
    email: $email
    tel: $tel
    gstNo: $gstNo
    noOfRooms: $noOfRooms
    address: $address
    isReady: $isReady
    status: $status
    enabled: $enabled
    panNo: $panNo
    bankDetails: $bankDetails

  }){
    id
    mob
    fName
    lName
    hotelName
    email
    tel
    gstNo
    noOfRooms
    status
    isReady
    refId
    enabled
    createdAt
    panNo
    address{
      addressLineOne
      addressLineTwo
      city
      state
      zip
      country
    }
    bankDetails{
	    bankName
	    accNo
	    ifscCode
	    accHolder
	    branchName
  }
  }
}`