import gql from 'graphql-tag'
export default gql`
mutation updateCheckedIn($id: String! $idType: String $idNumber: String){
  updateCheckedIn(id: $id, idType: $idType idNumber: $idNumber){
    id
    propertyName
    propertyId
    roomId
    status
    userId
    name
    email
    totalAmt
    bookingSlot
    noOfRooms
    noOfGuests
    bookingDate
    location
    addressLineOne
    addressLineTwo
    city
    state
    zip
    country
    idType
    idNumber
    createdAt
    checkInAt
    checkOutAt
  }
}
`;
