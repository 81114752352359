// **Prod
// export default {
//   graphqlEndpoint:"https://tuja4t6jg5avbdn4tgjh6fmtuu.appsync-api.ap-south-1.amazonaws.com/graphql",
//   region: "ap-south-1",
//   apiKey: "da2-xbji5thbufhfhieznr74athmzu",
//   authenticationType: "API_KEY",
//   bucket: "hours-stay-prod",
//   accessKeyId:"AKIAJRMKFWO3ZKEE6SGA",
//   secretAccessKey:"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",
//   userPoolId:"ap-south-1_VfYI12rKS",
//   userPoolWebClientId:"3las0orajlp6b49c9v15u8v0o4",
// };

// ***Dev
export default {
  graphqlEndpoint:"https://lb5djsf5djcclnnzkaiz4jmfhe.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  region: "ap-southeast-1",
  apiKey: "da2-z53s3vtgsjenpglk3do7klr6oq",
  authenticationType: "API_KEY",
  bucket: "hours-stay-dev",
  accessKeyId:"AKIAJIZOCEXK3IVMXFNQ",
  secretAccessKey:"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",
  userPoolId:"ap-southeast-1_4qgtvU7RP",
  userPoolWebClientId:"6sul7aksphb68i7kfh2jpo8td6",
};
