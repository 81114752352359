import React from 'react'
import App from '../App'
import { Link } from 'react-router-dom';
import { Icon, Button, Spin, Breadcrumb, Row,Col, Table, Modal, Popconfirm, Form, Input,message } from 'antd';
import { compose, graphql, withApollo } from 'react-apollo'
import getRoomsByPropertyId from '../query/getRoomsByPropertyId'


class Allrooms extends React.Component{
    constructor(props){
        super(props)
        this.state={
            visible: false,
            showComp:false,
            merchantData:[],
            loading:true,
            modal:{},
            rSelected:[]
        }
    }
    
    componentDidMount(){
       
        var propertyId = localStorage.getItem('propertyId')
        console.log("property Id form Allrooms",propertyId)
             this.props.client.query({
                      query:getRoomsByPropertyId,
                      variables:{ propertyId: propertyId,
                                     },
                      fetchPolicy: 'network-only',
                      })
                      .then(({ data }) => {
                        var psort = data.getRoomsByPropertyId
                        var finalsort= psort.sort((a, b) => a.roomNo > b.roomNo ? 1 : -1)
                         this.setState({
                          merchantData: finalsort,
                          loading:false
                        });
                      })
                      .catch(err => {
                          console.log(`Error ${JSON.stringify(err)}`)
                      })
     
          }
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], onSearch: true,search:true });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({
          searchText: '',
          nextToken: '',
          productList: [],
          loading: false,
          hasMore: true,
          search:false
          
        });
      };
      handleOk = () => {
        this.setState({
          visible: false,
        });
      }
      handleCancel = () => {
        this.setState({
          visible: false,
        });
      }
      showModal = (data) => {
        this.setState({
          visible: true,
          modal:data
        //   state:{merchantData:data}
        //  visible1:true
        });
      };
      onChangeHandle = (selectedRowKeys, selectedRows) => {
        this.setState({selectedRowKeys:selectedRowKeys,
                    selectedRows:selectedRows
                        })
        // this.props.onSelectData(selectedRowKeys,selectedRows)
      
      } 
      handleClick = ()=>{
        this.setState({showComp:true})
    }
    

    render(){
        const {selectedRowKeys,selectedRows} = this.state
        const rowSelection = {
          selectedRowKeys,selectedRows,
          onChange: this.onChangeHandle
        };
        console.log("selectedRowKeys",this.state.selectedRows)
      
        const columns = [ 
            {
              title: 'Room No',
              dataIndex: 'roomNo',
              key: 'roomNo',
              // align: 'center',
              // width: 100,
              // sorter: (b, a) => {
              //   return  a.roomNo -  b.roomNo
              // },
              // ...this.getColumnSearchProps('fName'),
            }
          ];
        return(
            <App header="Rooms List">
               <Row gutter={16}>
                <Col xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:22}}>
                        <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                            <Breadcrumb.Item>Manage Rooms</Breadcrumb.Item>
                        </Breadcrumb>
                       
                    </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:1}}>
                          
                            {this.state.selectedRows != undefined  && this.state.selectedRows != "" ?
                            <> 
                              <Link to={{pathname:"/bulkslotsallroom",
                            state:{roomId:this.state.selectedRows}}}
                            >
                                <Button type="default" style={{ marginBottom: 16, cursor: "pointer", background:"#389e0d", color:"#fff" }}  onClick={this.handleClick} >
                                    Next
                                </Button>
                                   </Link>
                                </>
                                : 
                                <>
                                <Button type="default" style={{ marginBottom: 16, }} disabled>
                                     Next
                                </Button>
                                </>}
                         
                    </Col>
                </Row>
       
                { !this.state.loading ?<Table rowSelection={rowSelection} columns={columns}  dataSource={this.state.merchantData} />: <Spin style={{height:'max',width:'800px'}} /> }
            </App>

        )
    }
}
export default withApollo(Allrooms) 