import gql from 'graphql-tag'
export default gql`
query{
    getSiteSetting{
      mId
      sortId
      contactEmail
      contactNo
      facebookLink
      instagramLink
      twitterLink
      commission
      facilities
      policiesAndRules
      roomCategory
      mobileBanners{
        bucket
        region
        key
      }
      logo{
        bucket
        region
        key
      }
      banners{
        bucket
        region
        key
      }
      advertiseBanners{
        banners{
          bucket
          key 
          region
       }
       displayOrder
       link
     }
    }
  }`
  