import React from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, message,Modal,Icon,InputNumber  } from 'antd'
import { graphql, compose, withApollo } from 'react-apollo';
import moment from 'moment';
import App from '../App'
import getSlotsByBatchIds from '../query/getSlotsByBatchIds'
import getProperty from '../query/getProperty'
import updateOnBookingExtended from '../mutation/updateOnBookingExtended'
import updateSlot from '../mutation/updateSlot'
const FormItem = Form.Item

class EditcheckIn extends React.Component{
    constructor(props){
        super(props)
        this.state={
            createdslote:[],
            checkslot:[],
            PropertyData:[],
            selected:[],
            cselected:[],
            finalslotArr:[],
            disable: false,
            loading:false,
            slotdate:'',
            available:true,
            nextslot:'',
            nextslotC:'',
            stnextslot:'',
            allselected:[],
            allRoomsData:[],
            updatedSlot:[],
            ext:true

        }
    }
    handleclick = (i) => {
        console.log('clicked',i);
        let arr =this.state.selected
        arr.push(i) 
        console.log("final data",arr)
        let checkoutslot = this.props.location.state.data.bookingSlot.slice(-1)[0].split(':00');
        let gd = checkoutslot[0];
        let slectedslot = i;
        let as = slectedslot.split(':00');
        let uj = as[0];
       
        
        let sortArr = arr.sort()
        let sortS = sortArr[0]
        let arraPars = parseInt(sortS);
        let ck = arraPars-gd
        let same = gd-ck
        console.log("subtraction",gd,uj,ck,sortS,sortArr,arraPars)
        this.setState({ selected:arr, 
                        finalslotArr:arr,
                        cselected:arr,
                        allselected:arraPars,
                        nextslot:ck,
                        nextslotC:uj,
                        stnextslot:same,
                        disable: true,
                        ext: false})
        
      }
      enterLoading = ()=>{
        this.setState({
            loading:true
        })
    }
    componentDidMount(){
        this.enterLoading()
        var merchantId = localStorage.getItem('refId')
        // console.log("propertyId",propertyId)
        this.props.client.query({
            query:getProperty,
            variables:{ merchantId: merchantId
            },
            fetchPolicy: 'network-only',
            })
            .then(({ data }) => {
              console.log("property data",data)
            //   this.createDatasource(data.getProperty)
               this.setState({
                PropertyData: data.getPropertyByMerchantId.attachedRoomCategories[0],
                loading:false
              });
            })
            .catch(err => {
                console.log(`Error ${JSON.stringify(err)}`)
            })
        }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log("form data :: ", values)
            var slotavalable = this.state.available
            if(!err){
               
                var rid = this.props.location.state.data.roomId != undefined ? this.props.location.state.data.roomId : '';
               var ckeckbslot = this.props.location.state.data.bookingSlot.slice(-1)[0].split(':00')
                if(ckeckbslot[0] >=23){
                    var bdate = this.props.location.state.data.bookingDate
                    
                    var date =  moment(bdate,'DD-MM-YYYY').add("days",1).format('DD-MM-YYYY')
                    console.log("added date",date)
                    this.setState({
                        getdate:date
                    })
                }
                else{
                    var date = this.props.location.state.data.bookingDate != undefined ? this.props.location.state.data.bookingDate : '';
                    console.log("not next day slot")
                }
                this.enterLoading()
                this.props.client.query({
                    query:getSlotsByBatchIds,
                    variables:{ roomIds: this.props.location.state.data.roomId, 
                        dateParam: date,
                                   },
                    fetchPolicy: 'network-only',
                    })
                    .then(({ data }) => {
                      console.log("Slots data",data)
                      var result = data.getSlotsByBatchIds;
                      var arr = result.map((val) => val.slotsList)
                    //   var alrr = result.map((val) => val.slotsList)
                      console.log("arrr.arr",arr)
                    //   var sarr = arr.map((val) => (val.time ))
                    //   var tarr = arr.map((val) => (val.available))
                    //   console.log("array data::",sarr)
                    //   console.log("array available",tarr)
                       this.setState({
                        createdslote: arr[0],
                        checkslot:arr,
                        allRoomsData:result,
                        slotdate:data.getSlotsByBatchIds[0].slotDate,
                        loading:false
                      });
                      
          
                    })
                    .catch(err => {
                        console.log(`Error ${JSON.stringify(err)}`)
                        message.error('Slot not Created please Create Shots');
                    })
            }
      
            if(this.state.finalslotArr.length >=1 ){
                
                let d = this.props.location.state.data.bookingDate 
                let date = moment(d,'DD/MM/YYYY').format('ddd')
                console.log("day",date)
                var extendedAmount
                {date == 'Sun' || date == 'Sat' ?
                extendedAmount = this.state.finalslotArr.length * this.state.PropertyData.additionalRate2
                : 
                extendedAmount = this.state.finalslotArr.length * this.state.PropertyData.additionalRate1
                }
                let sort =this.props.location.state.data.bookingSlot[0].split(':00')
                this.state.checkslot.map((val) =>{
                    console.log("checkslot val",val, this.state.checkslot, this.state.selected)
                    // if(JSON.stringify(aarr)==JSON.stringify(this.state.selected))
                    // {
                    //    console.log("in if")
                    // }
                    // console.log("out if")
                    if(slotavalable == true){

                    
                    val.map((p,i) =>{
                        // console.log("p.time == this.state.selected",p.time == this.state.selected)
                        this.state.selected.map((values) =>{
                            if(p.time == values)
                            {
                                console.log("p.time == this.state.selected",p.time == values,p,values)

                                if(p.available == false){
                                    // this.setState({
                                    //     available: false
                                    //   });
                                    slotavalable = false
                                    message.error('Another rooms slot not available');
                                }
                                console.log("error")
                            } 
                        })
                      
                        console.log("out if")
                    })
                }
                })
                console.log("extended Amount",extendedAmount);
             
            
               
                if(slotavalable != false &&( this.state.nextslot == 1  || this.state.nextslot == -23)){
                   if(this.state.nextslotC == 1 && this.state.stnextslot > 0 || this.state.nextslot != this.state.nextslotC) {
                    // message.success('Booking Extended sucessfully fist if');
                    // this.update()
                    this.enterLoading()
                    this.props.client.mutate({
                        mutation: updateOnBookingExtended,
                        variables: {
                                id: this.props.location.state.data.id,
                                extendedAmt : extendedAmount,
                                extendedSlots: this.state.finalslotArr
                            }
                       
                    }).then((data) => {
                        message.success('Booking Extended sucessfully');
                        console.log(" Booking Extended success",data)
                        this.setState({
                            data:data
                          }
                          ,()=>(
                            this.update()
                            )
                            )
                  
                        })
                        .catch(err => {
                          console.log(`Error ${JSON.stringify(err)}`)
                      })
                    }else{
                        message.error('Please select continues slots else');
                        this.setState({
                            loading:false
                          })
                    }
        
                }else{
                    if(slotavalable != false){
                     
                        message.error('Please select continues slots');
                        this.setState({
                            loading:false
                          })
                          this.props.history.push('/manage-booking')
                    }
                }
    
            }

        });
      }
       handleclick1 =()=>{
        this.props.history.push('/manage-booking')
    }
    update(){
       let fup=[] 
        try{
            this.state.allRoomsData.map((value,i) =>{
            value.slotsList.map((val) =>{
                console.log("val",val)
                    this.state.finalslotArr.map((stime) =>{
                        // console.log("condition check",stime,val.time)
                        if(val.time == stime && val.available == true){
                        //   console.log("condition check succes")
                          val.available = false;
                        }
                    })
            }) 

// console.log("this.state.allRoomsData.slotsList",this.state.allRoomsData)
// console.log("roomids for update", this.state.finalslotArr,this.state.allRoomsData,value.roomId,value.slotDate,value.slotsList)
 value.slotsList.map((dd)=>{
    delete dd.__typename
    fup.push(dd)
    // console.log("DD",dd)
})
console.log("delete type",fup)
            this.props.client.mutate({
                mutation: updateSlot,
                variables: {
                    roomId: value.roomId,
                    slotDate: value.slotDate,
                    slotsList:fup,
                    epochDate: value.epochDate,
                    enabled: 'true'
                    
                    }
               
            }).then((data) => {
                // message.success('Slot Updated sucessfully');
                // console.log("success",data)
                // let sData = this.state.slotdata
                this.props.history.push('/manage-booking')
                })
                .catch(err => {
                  console.log(`Error ${JSON.stringify(err)}`)
                  this.setState({
                    loading:false
                  })
              })
        })
    }catch(err){
        console.log("err",err)
    }
    }
      
    render(){
        
        // console.log("this.state.PropertyData",this.state.PropertyData)
        // console.log("edit data",this.state.getdate)
        // console.log("ckeckout time",this.props.location.state.data.bookingSlot.slice(-1)[0].split(':00'))
        var ckeckbslot = this.props.location.state.data.bookingSlot.slice(-1)[0].split(':00')
        var bookedslots = this.props.location.state.data.bookingSlot[0].split(':00')       
        const { getFieldDecorator, getFieldValue } = this.props.form;
        var product = []

        // this.state.createdslote.map((value,i) =>{
        //     console.log('value',value)
        //     value.map((p,i)=>{
        //         <>
        //             {bookedslots[0] <= i ? 
        //             <FormItem>
        //             {getFieldDecorator(`extendedSlots`, {
        //               initialValue: p,
        //                       rules: [
        //                       ],
        //                     })(
        //                     <Col md={{span:1}}>
        //                         <div style={{ display: "flex" }}>
        //                             <Button value={value.time} style={{ marginTop: "5px", marginRight: '10px' }} onClick={()=>{ this.handleclick(value.time)}} disabled={this.state.selected.indexOf(value.time) != -1 ? true : false || value.available != false ? false : true }>{value.time}</Button>
        //                         </div>
        //                     </Col>)}
        //                     </FormItem>
        //                     :''}
        //                     </>

        //     })
        // })
//      for(var i=0; i<=this.state.createdslote.length-1;i++){
//          for(var j=0;j<=this.state.createdslote[i].length;j++){
//             //  console.log("in j forloop",this.state.createdslote[j])
//              product=this.state.createdslote[j];
// console.log("product",product)
// }
// }
            
           const  ListItam = this.state.createdslote.map((value,i) =>
           <>
           {bookedslots[0] <= i || this.props.location.state.data.bookingDate != this.state.slotdate ? 
           <FormItem>
           {getFieldDecorator(`extendedSlots`, {
             initialValue: value,
                     rules: [
                     ],
                   })(
                   <Col md={{span:1}}>
                       <div style={{ display: "flex" }}>
                           <Button value={value.time} style={{ marginTop: "5px", marginRight: '10px' }} onClick={()=>{ this.handleclick(value.time)}} disabled={this.state.selected.indexOf(value.time) != -1 ? true : false || value.available != false ? false : true }>{value.time}</Button>
                       </div>
                   </Col>)}
                   </FormItem>
                   : ''}
            </>
       );
         

        return(
            <App header={"Extend Slots"}> 
                <Form onSubmit={this.handleSubmit}>
                    {/* <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="How Many Hours Extends">
                                {getFieldDecorator(`hours`, {
                                    rules: [{
                                        required: true,
                                        message: "How Many Hours Extends",
                                    },
                                    ]
                                })(
                                    <InputNumber min={1} max={10} />
                                )}
                            </FormItem>
                        </Col>
                    </Row> */}
                    <h3>Booking Date {this.props.location.state.data.bookingDate}</h3>
                    {this.state.getdate != null && this.state.getdate != undefined ? <h3>Slot date {this.state.getdate}</h3> : ''}
                    <Row gutter={24} style={{ marginTop: '5px' }} type="flex">{ListItam}</Row>
                    {/* {
                         this.state.createdslote.map((value,i) =>{
                            console.log('value',value)
                            return(
                             <Row gutter={24}style={{marginBottom: '20px'}}>
                             {
                                            value.map((p,i)=>{
                                                console.log("P",p)

                                                // <>
                                                //     {bookedslots[0] <= i ? 
                                                //     // <FormItem>
                                                //     // {getFieldDecorator(`extendedSlots`, {
                                                //     //   initialValue: p,
                                                //     //           rules: [
                                                //     //           ],
                                                //     //         })(
                                                    return(
                                                        <>
                                                            <Col md={{span:3}}>
                                                                <div style={{ display: "flex" }}>
                                                                    <Button value={p.time} style={{ marginTop: "5px", marginRight: '10px' }} onClick={()=>{ this.handleclick(p.time)}} disabled={this.state.selected.indexOf(p.time) != -1 ? true : false || p.available != false ? false : true }>{p.time}</Button>
                                                                </div>
                                                            </Col>
                                                            </>
                                                            )
                                                //             // )}
                                                //             // </FormItem>
                                                //             :''}
                                                //             </>

                                            })
                             }
                             </Row>
                            )
                           
                        })
                    } */}
                    <Row gutter={16}>
                        <Col span={24}>
                            {this.state.createdslote.length <=2 ?
                            <>
                             <Button type="primary" onClick={this.handleSubmit} loading={this.state.loading} style={{ background: "#389e0d", color: "#fff",marginTop: '10px', marginBottom: "5%", marginRight: "20px" }} >
                             Check Availability
                         </Button>
                         <Button type="default" style={{ background: "#f44336", color: "#fff", marginBottom: "5%" }} onClick={this.handleclick1}>Cancel</Button> 
                         </>
                         :
                         <>
                         
                         <Button type="primary" htmlType="submit" disabled={this.state.ext ? true : false} loading={this.state.loading} style={{ background: "#389e0d", color: "#fff",marginTop: '10px', marginBottom: "5%", marginRight: "20px" }} >
                         Extend Booking
                     </Button>
                     <Button type="default" style={{ background: "#f44336", color: "#fff", marginBottom: "5%" }} onClick={this.handleclick1}>Cancel</Button>
                     
                     </>
                     }
                        </Col>
                    </Row>
                </Form>
            </App>
        )
    }
}

const WrappedEditcheckIn = Form.create()(EditcheckIn);
export default withApollo(WrappedEditcheckIn)