import React, { Component } from 'react'
import { Icon, Button, Spin, Breadcrumb, Row,Col, Table, Modal,Card,Switch, Popconfirm, Form, Input,message } from 'antd';
import moment from 'moment';
class bookingDeComp extends Component {


    render() {
        let data=this.props.data
        var sort = []
        data&&data.roomId.map((i, j)=>{
var a=i.split('::')
sort.push(a[1])
        })

        return (
            <div>
                {/* {console.log("this.props", this.props.data)} */}
                <Row>
                    <Col span={8}>
                        <h3 style={{ margin: 0, marginTop: '20px' }}>Booking Date:</h3>
                        <div>{data && data.bookingDate ? data.bookingDate :'--'}</div>
                    </Col>
                   
                    <Col span={8}>

                        <h3 style={{ margin: 0, marginTop: '20px' }}>Name:</h3>
                        <div>{data&&data.name?data.name:'--'}</div>
                    </Col>
                    <Col span={8}>
                        <h3 style={{ margin: 0, marginTop: '20px' }}>Mobile No.:</h3>
                        <div>{data&&data.userId?data.userId:'--'}</div>
                    </Col>


                </Row>

                <Row>

                    <Col span={8}>
                        <h3 style={{ margin: 0, marginTop: '20px' }}>Email:</h3>
                        <div>{data&&data.email?data.email:'--'}</div>
                    </Col>

                   
                    <Col span={8}>

                        <h3 style={{ margin: 0, marginTop: '20px' }}>Id:</h3>
                        <div>{data&&data.id?data.id:'--'}</div>
                    </Col>

                    <Col span={8}>

                        <h3 style={{ margin: 0, marginTop: '20px' }}>Created Date:</h3>
                        <div>{data&&data.createdAt?moment.unix(data.createdAt).format("DD-MM-YYYY"):'--'}</div>
                    </Col>

</Row>

<Row>

                    <Col span={8}>
                        <h3 style={{ margin: 0, marginTop: '20px' }}>Extended Amount:</h3>
                        <div>{data&&data.extendedAmt?data.extendedAmt:'--'}</div>
                    </Col>

                   
                    <Col span={8}>
                        <h3 style={{ margin: 0, marginTop: '20px' }}>Total Amount:</h3>
                        <div>{data && data.totalAmt?data.totalAmt:'--'}</div>
                    </Col>
                    <Col span={8}>
                        <h3 style={{ margin: 0, marginTop: '20px' }}>Grand Total:</h3>
                        <div>{data&&data.grandTotal?data.grandTotal:'--'}</div>
                    </Col>
{/* 
                    <Col span={8}>
                        <h3 style={{ margin: 0, marginTop: '20px' }}>No Of Rooms:</h3>
                        <div>{data&&data.noOfRooms?data.noOfRooms:'--'}</div>
                    </Col> */}


                </Row>

                <Row>

                    <Col span={8}>
                        <h3 style={{ margin: 0, marginTop: '20px' }}>CheckIn :</h3>
                        <div>{data&&data.Inslot?data.Inslot:'--'}</div>
                    </Col>

                    <Col span={8}>
                        <h3 style={{ margin: 0, marginTop: '20px' }}>CheckOut :</h3>
                        <div>{data&&data.outSlot?data.outSlot:'--'}</div>
                    </Col>

                    <Col span={8}>

                        <h3 style={{ margin: 0, marginTop: '20px' }}>No Of Guests:</h3>
                        <div>{data&&data.noOfGuests?data.noOfGuests:'--'}</div>
                    </Col>
                   

                </Row>

                <Row>

<Col span={8}>
    <h3 style={{ margin: 0, marginTop: '20px' }}>Coupon Amount:</h3>
    <div>{data&&data.couponAmt?data.couponAmt:'--'}</div>
</Col>

<Col span={8}>
    <h3 style={{ margin: 0, marginTop: '20px' }}>Coupon Code:</h3>
    <div>{data&&data.couponCode?data.couponCode:'--'}</div>
</Col>

<Col span={8}>
    <h3 style={{ margin: 0, marginTop: '20px' }}>No Of Rooms:</h3>
    <div>{data&&data.noOfRooms?data.noOfRooms:'--'}</div>
</Col>


</Row>
<Row>
<Col span={8}>
    <h3 style={{ margin: 0, marginTop: '20px' }}>Extended Slots:</h3>
    <div>{data&&data.extendedSlots?data.extendedSlots.join(', '):'--'}</div>
</Col>


<Col span={8}>
<h3 style={{ margin: 0, marginTop: '20px' }}>Id Proof Type:</h3>
    <div>{data&&data.idType?data.idType:'--'}</div>
</Col>
    

<Col span={8}>
<h3 style={{ margin: 0, marginTop: '20px' }}>Id Proof Number:</h3>
    <div>{data&&data.idNumber?data.idNumber:'--'}</div>
</Col>

</Row>                

<Row>


<Col span={8}>

    <h3 style={{ margin: 0, marginTop: '20px' }}>Payment State:</h3>
    <div>{data&&data.paymentState?data.paymentState=='PAH'?'Pay At Hotel':'Online':'--'}</div>

</Col>
<Col span={8}>
    <h3 style={{ margin: 0, marginTop: '20px' }}>Room No:</h3>
<div>{sort.join(', ')}</div>    
</Col>
</Row>                

            </div>
        )
    }
}

export default bookingDeComp
