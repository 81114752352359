import React from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Icon, Button, Spin, Breadcrumb, Row,Col, Select, DatePicker, InputNumber, Form, Input,message } from 'antd';
import { compose, graphql, withApollo } from 'react-apollo'
import App from '../App'
import getSlotByDate from '../query/getSlotByDate'
const dateFormat = 'DD-MM-YYYY';
const FormItem = Form.Item
class Viewslots extends React.Component{
    constructor(props){
        super(props)
        this.state={
            date:'',
            loading: false,
            createdslote:[],
            querydata:[],
            checkq:'',
            fcheck:''
        }
    }
    onChangedate = (value,value1)=> {
        console.log(`selected ${value1}`);
        var a = moment(value).format("DD-MM-YYYY");
        var b = moment().unix();
        // var a = value1;
        // var b = moment.unix(value/1000)
        // var c = moment(value).getTime()/1000
        // a = moment.format("YYYY/MM/DD")
       console.log('a',a)
    //     console.log('new value',b);
        this.setState({
          date:a,
        //   epochDate:b
        });
         
      }
      enterLoading = ()=>{
        this.setState({
            loading:true
        })
    }
      handleSubmit = e => {
          console.log("in view slot")
       
          try{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            console.log("form data :: ", values,this.state.date)
            if(!err){
                let rid = this.props.location.pathname
                let roomId = rid.replace("/viewslots/",'')
                console.log("roomid",roomId)
                this.enterLoading()
                this.props.client.query({
                    query:getSlotByDate,
                    variables:{ roomId: roomId, 
                                slotDate: this.state.date,
                                   },
                    fetchPolicy: 'network-only',
                    })
                    .then(({ data }) => {
                      console.log("Slots data",data)
                      var result = data.getSlotByDate;
                      var arr = result.slotsList
                      var sarr = arr.map((val) => (val.time ))
                      var tarr = arr.map((val) => (val.available))
                      console.log("array data::",sarr)
                      console.log("array available",tarr)
                       this.setState({
                        createdslote: data.getSlotByDate.slotsList,
                        querydata:sarr,
                        loading:false,
                        checkq:data
                      });
                      
          
                    })
                    .catch(err => {
                        console.log(`Error ${JSON.stringify(err)}`)
                        message.error('Slot not Created please Create Slots');
                        this.setState({
                        createdslote:null,
                        loading:false,
                        fcheck:null
                        })
                    })
            }

        });
    }
    catch(err){
        console.log("err",err)
    }
      }
      handleclick =()=>{
        this.props.history.push('/roomslist')
    }
   
   
    render(){
        var fgh = undefined
        let rid = this.props.location.pathname
        let roomId = rid.replace("/viewslots/",'')
        console.log("roomId",roomId)
        console.log("room data from create slots",this.state.createdslote)
        const { getFieldDecorator, getFieldValue,} = this.props.form;
        // let result = this.state.createdslote.getSlotByDate.map((a) => (a));
        // var querydata1 = this.state.createdslote.getSlotByDate
        // console.log("querydata1",result)


        var slot = this.state.createdslote
        // const ListItam = slot.map((value,i) =>
        //     <>
        //         <Col span={3}>
        //         <div style={{ display: "flex"}}>
        //         <Button value={value.time} style={{ marginTop: "10px" }} disabled={value.available == false ? true : false }>{value.time}</Button>
        //         </div>
        //         </Col>
        //     </>
        // );
       
        return(
            <App header="Manage Rooms">
                <Form onSubmit={this.handleSubmit}>
            <Row gutter={16}>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                        <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                        <Link to="/roomslist">
                            <Breadcrumb.Item>Manage Rooms</Breadcrumb.Item>
                        </Link>
                        </Breadcrumb>
                    </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}}>
                        {/* <Link to="/"> */}
                            {/* <Link to="/cratebulkslot">
                                <Button type="default" style={{ marginBottom: 16, float: "right", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                                    Create Slots In Bulk
                                </Button>
                            </Link> */}
                        {/* </Link> */}
                    </Col>
                    <div>
                        <div style={{fontWeight:'bold'}}><br/><br/>Room Id:- {roomId}</div>
                    </div>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} style={{marginBottom: '-20px'}}>
                    <FormItem
                            label="Select Date"
                        >
                            {getFieldDecorator(`Date`, {
                                rules: [{
                                    required: true
                                }]
                            })(
                                <DatePicker onChange={this.onChangedate}
                                format={dateFormat} />
                            )}
                        </FormItem>

                    </Col>
                

            </Row>
            {  fgh == this.state.checkq || (fgh != this.state.checkq  && this.state.createdslote != null )?
            <>
                        {
                         slot.map((value,i) =>{
                            return(
                                    <Col span={3}>
                                    <div style={{ display: "flex"}}>
                                    <Button value={value.time} style={{ marginTop: "10px" }} disabled={value.available == false ? true : false }>{value.time}</Button>
                                    </div>
                                    </Col>
                            )
                                            
                        })
                    }
            {/* <Row gutter={24} style={{ marginTop: '5px' }} type="flex">{ListItam}</Row> */}
      <Row gutter={16}>
        
                    <Col span={24}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginTop: "15px",marginRight:"20px" }} >
                          Get Slots
                        </Button>
                        <Button type="default" style={{background:"#f44336",marginTop: "15px", color:"#fff" }}  onClick={this.handleclick} >Cancel</Button>
                    </Col>    
                </Row>
                </>
                : <>
                <Col span={24}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginTop: "15px",marginRight:"20px" }} >
                          Get Slots
                        </Button>
                        <Button type="default" style={{background:"#f44336",marginTop: "15px", color:"#fff" }}  onClick={this.handleclick} >Cancel</Button>
                    </Col>   
                    </>}
            </Form>
            </App>
        )
    }
}
const WrappedViewslots = Form.create()(Viewslots);
const Viewslot = compose(
    graphql(getSlotByDate, {
      name: "getSlotByDate"
    }),
   
  )(WrappedViewslots);
export default withApollo(Viewslot)