import React from 'react'
import { Icon, Button, Spin, Breadcrumb, Row,Col, Select, DatePicker, InputNumber, Form, Input,message } from 'antd';
import {compose, graphql, withApollo } from 'react-apollo'
import { Link } from 'react-router-dom';

import updateSlot from '../mutation/updateSlot'
const FormItem = Form.Item
class Updateslot extends React.Component{
    constructor(props){
        super(props)
        this.state={
            selected:[],
            disable: false,
            slotdata: this.props.slotdata,
            loading:false,
        }
    }
    handleclick = (i) => {
        console.log('clicked',i);
        let arr =this.state.selected
        arr.push(i) 
        console.log("array",arr)
        if(this.state.disable == false){
          this.setState({selected:arr, disable: false})
        }
        else{
          this.setState({selected:arr, disable: true})
        }
       
        
      }
      update = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log("form data :: ", values.slots)
          if (!err) {
            this.enterLOading()
            let finalArr = values.slots

            this.state.selected.map( i => finalArr[i].available = false)

            console.log("FinalArr :: ", finalArr)
            console.log("jdgs",values.slots)
            this.props.client.mutate({
                mutation: updateSlot,
                variables: {
                    roomId: this.props.roomId,
                    slotDate: this.props.date,
                    slotsList: finalArr,
                    epochDate: this.props.epochDate,
                    enabled: 'true'
                
                    }
               
            }).then((data) => {
                message.success('Slot Updated sucessfully');
                console.log("success",data)
                // let sData = this.state.slotdata
                this.setState({
                  slotDate:finalArr,
                  loading:false,
                })
    
                })
                .catch(err => {
                  console.log(`Error ${JSON.stringify(err)}`)
                  this.setState({
                    loading:false
                  })
              })

            
          }
        });
      };

      enterLOading=()=>{
        this.setState({
          loading:true
        })
      }
    //   update=() =>{
    //       console.log("update fun")
    //   }
    render(){
      // if(this.props.slotdata.getSlotByDate == null){
      //   return(
      //     <div style={{ color: 'red'}}>*Slot not created</div>
      //   );
      // }
        console.log("props in update",this.props.slotdata)
        var slotdata = this.props.slotdata.slotsList
        console.log("slot data",slotdata)
        const { getFieldDecorator, getFieldValue,} = this.props.form;
        const ListItam = slotdata.map((hourIntervals,i) =>
        // console.log("asiuiu",hourIntervals)
        <>
        <Col span={3}>
          <div style={{ display: "flex"}}>
          <FormItem>
          {getFieldDecorator(`slots[${i}]time`, {
            initialValue: hourIntervals.time,
                    rules: [
                      // {
                      //   type: 'email',
                      //   message: 'The input is not valid E-mail!',
                      // },
                      // {
                      //   required: true,
                      //   message: 'Please input your E-mail!',
                      // },
                    ],
                  })(<Col md={{span:1}}><Button value={hourIntervals.time} onClick={()=>{ this.handleclick(i)}} style={{ marginTop: "5px" }} disabled={(hourIntervals.available == false ? true : false || this.state.selected.indexOf(i) != -1 ? true : false )}>{hourIntervals.time}</Button></Col>)}
                  </FormItem>
                  {/* </Col> */}
                  {/* <Col span={2}> */}
          <FormItem>
          {getFieldDecorator(`slots[${i}]available`, {
            initialValue: hourIntervals.available,
                    // rules: [
                    //   {
                    //     type: 'email',
                    //     message: 'The input is not valid E-mail!',
                    //   },
                    //   {
                    //     required: true,
                    //     message: 'Please input your E-mail!',
                    //   },
                    // ],
                  })}
            </FormItem>
            </div>
          </Col>
        
        </>
        );

        return(
            <Form onSubmit={this.handleSubmit}>
            <div>
                  <h4>Please select slots to make it unavailable</h4>
                  <Row gutter={24} style={{ marginTop: 10 }} type="flex">{ListItam}</Row>
            </div>
            <Row gutter={16}>
        
            <Col span={24}>
                <Button type="primary" onClick={this.update } loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginTop: "15px",marginBottom:"5%",marginRight:"20px" }} >
                    Update Slot
                </Button>
                <Link to="/roomslist">

                <Button type="default" style={{background:"#f44336",marginTop: "15px", color:"#fff",marginBottom:"5%" }}  onClick={this.handleclick1} >Cancel</Button>
               </Link>
                </Col>  
                  
            </Row>
        </Form>
        );
    }
}
const WrappedUpdateslot = Form.create()(Updateslot);
export default withApollo(WrappedUpdateslot)