import React from 'react';
import { Suspense, lazy } from "react";
import { Button, Col, Icon, Layout, Menu,  Tooltip, Row } from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import { Auth } from "aws-amplify";
import getPropertyInshort from './query/getPropertyInshort'
const AdminApp = React.lazy(() => import('./components/AdminApp'));
const MerchantApp = React.lazy(() => import('./components/merchantApp'));


const { SubMenu } = Menu;
export const  MyContext = React.createContext();
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role:'',
      data:'',
      refId: '',
      loading:false
    };

    this.getData()
  }
  enterLoading = ()=>{
    this.setState({
        loading:true
    })
}

  
  getData = async()=>{
   await  Auth.currentUserInfo()
    .then((data) => {
      // console.log("merchant",data.attributes)
      localStorage.setItem('refId',data.attributes['custom:refId']);
      localStorage.setItem('username',data.username)
      this.setState({
        refId:data.attributes['custom:refId'],
        data:data,
       
      },()=>(

        this.getproperty(data.attributes['custom:refId'])
      )
      )
      if(data.attributes['custom:role'] == undefined ){
        
        this.setState({
          role:"Admin"
        })
        // console.log("Admin")
      }else{
        // localStorage.setItem('role',data.attributes['custom:role'])
        // console.log("merchant")
        this.setState({
          role:"merchant"
        })
      }
        // this.setState({
        //     role:data.attributes['custom:role'],
        // })
    })
    .catch(err => console.log(err));
  }
  getproperty(refId){
    // console.log("getproperty",)
    if(this.state.data.attributes['custom:role'] == undefined && this.state.data.attributes['custom:role'] != "merchant"){
      console.log("admin")
    }else{
         this.props.client.query({
                  query:getPropertyInshort,
                  variables:{ merchantId:refId,
                                 },
                  })
                  .then(({ data }) => {
                    // console.log("AjayAjay form main",data.getPropertyByMerchantId.propertyId)
                    localStorage.setItem('propertyId', data.getPropertyByMerchantId.propertyId);
                  })
                  .catch(err => {
                      console.log(`Error ${JSON.stringify(err)}`)
                  })
                }
 
      }
  render(){
    // console.log("role",this.state.role)
    const { role, user } = this.state;
    const {children} = this.props
    if(this.state.role != undefined  && this.state.role != ''){
      if(this.state.role == "merchant"){
        // console.log("merchat in main")
      // localStorage.setItem('siteType',this.state.siteType)
      return (
        <Suspense fallback={null}>
          <MerchantApp role={role}>
            {children}
          </MerchantApp>
        </Suspense>
      );

    }else{
      // if(this.state.role == "Admin")
      // console.log("admin in ma")
        return(
        <Suspense fallback={null}>
          <MyContext.Provider value={this.state.data}>
          <AdminApp>
            {children}
          </AdminApp>
          </MyContext.Provider>
        </Suspense>
        );
    }
  }
  else{
    return null
  }
 
}
}
export default withApollo(Main);
