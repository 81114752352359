import gql from 'graphql-tag'
export default gql`
query getSlotByDate(
  $roomId: String!
  $slotDate: String!
){
  getSlotByDate(roomId: $roomId slotDate: $slotDate){
    id
    roomId
    slotDate
    slotsList{
      time
      available
    }
    roomId
    epochDate
    enabled
    createdAt
  }
}
`
//  {
//        "roomId": "8-prince-hotel::10",
//        "slotDate": "30-11-2019"
//      }
