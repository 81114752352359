import gql from 'graphql-tag'
export default gql`
query getPropertyByMerchantId( $merchantId: String!){
  getPropertyByMerchantId( merchantId: $merchantId){
    attachedRoomCategories{
        property
        propertyId
        roomCategory
        baseRate1
        baseRate2
        additionalRate1
        additionalRate2
        guestRate1
        guestRate2
        size
        occupancy
      }
  }
}`