import React from 'react'
import { Route, BrowserRouter as Router } from "react-router-dom";
import Userlist from './userlist'
import MerchantProfile from './MerchantProfile'
import ManageBooking from './manageBooking'
import ManageProperty from './manageProperty'
import CreateProperty from './createProperty'
import ManageRooms from './manageRooms'
import Roomslist from './Roomslist'
import Modelview from './Modalview'
import Viewslots from './createslot'
import CreateBulkSlot from './createBulkSlot'
import CheckIn from './CheckIn'
import EditcheckIn from './EditcheckIn'
import Allrooms from './allrooms'
import CreateBulkSlotallRooms from './createbulkslotallRooms'
import EditRoom from './EditRoom'
import Reviews from './Reviews'
import {MyContext} from '../main'
import App from '../App'
class Routers extends React.Component{
    constructor(props){
        super(props)
        this.state={
            data:this.props
        }
    }
    
    render(){
        // console.log("Routers props data",this.props.userdata)

        
// var id = `custom:refId`

//         if(this.context.attributes != undefined)
//         var id = this.context.attributes['custom:refId']
//         console.log("Context data", this.context.attributes['custom:refId'])

    return(
    
    <Router>
        <React.Fragment>
            <Route exact path="/" component={MerchantProfile} />
            <Route path="/userlist" component={Userlist} />
            <Route path="/manage-booking" component={ManageBooking} />
            <Route path="/manage-property" component={ManageProperty} />
            <Route path="/create-property" component={CreateProperty} />
            <Route path="/manage-rooms" component={ManageRooms} />
            <Route path="/roomslist" component={Roomslist} />
            <Route path="/edit_slots" component={Modelview} />
            <Route path="/viewslots/:id" component={Viewslots} />
            <Route path="/cratebulkslot" component={CreateBulkSlot} />
            <Route path="/checkIn" component={CheckIn} />
            <Route path="/editcheckin" component={EditcheckIn} />
            <Route path="/allrooms" component={Allrooms} />
            <Route path="/bulkslotsallroom" component={CreateBulkSlotallRooms} />
            <Route path="/edit_room" component={EditRoom}  />
            <Route path="/reviews" component={Reviews} />

            {/* <Route path="/" render={() => { 
            return (
                   <MerchantProfile {...this.props} /> 
                  )
               
            }}  /> */}
            

       



        </React.Fragment>
    </Router>
)
        }
        }
Routers.contextType = MyContext
export default Routers