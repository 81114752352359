import React from 'react'
import App from '../App'
import { Link } from 'react-router-dom';
import { Icon, Button, Spin, Breadcrumb, Row,Col, Table, Modal, Popconfirm, Form, Input,message } from 'antd';
import { compose, graphql, withApollo } from 'react-apollo'
import getRoomsByPropertyId from '../query/getRoomsByPropertyId'
import Modalview from './Modalview'
import CardBody from 'antd-mobile/lib/card/CardBody';

class Roomlist extends React.Component{
    constructor(props){
        super(props)
        this.state={
            visible: false,
            merchantData:[],
            loading:false,
            modal:{},
            rSelected:[]
        }
    }
    enterLoading = ()=>{
        this.setState({loading:true})
    }
    componentDidMount(){
       this.enterLoading()
        var propertyId = localStorage.getItem('propertyId')
        console.log("property Id form roomlist",propertyId)
             this.props.client.query({
                      query:getRoomsByPropertyId,
                      variables:{ propertyId: propertyId,
                                     },
                      fetchPolicy: 'network-only',
                      })
                      .then(({ data }) => {
                        var fgh = data.getRoomsByPropertyId
                        var i= fgh.sort((a, b) => a.roomNo > b.roomNo ? 1 : -1)
                         this.setState({
                          merchantData: i,
                          loading:false
                        });
                      })
                      .catch(err => {
                          console.log(`Error ${JSON.stringify(err)}`)
                      })
     
          }
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], onSearch: true,search:true });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({
          searchText: '',
          nextToken: '',
          productList: [],
          loading: false,
          hasMore: true,
          search:false
        });
      };
      handleOk = () => {
        this.setState({
          visible: false,
        });
      }
      handleCancel = () => {
        this.setState({
          visible: false,
        });
      }
      showModal = (data) => {
        this.setState({
          visible: true,
          modal:data
        //   state:{merchantData:data}
        //  visible1:true
        });
      };

    

    render(){
      
   
        const columns = [ 
            {
              title: 'Room No',
              dataIndex: 'roomNo',
              key: 'roomNo',
              width: 250,
              align: 'center'
              // sorter: (b, a) => {
              //   return  a.roomNo -  b.roomNo
              // },
              // ...this.getColumnSearchProps('fName'),
            },
            // {
            //   title: 'Room Name',
            //   dataIndex: 'roomId',
            //   key: 'lName',
            //   // ...this.getColumnSearchProps('lName'),
            // }, 
             {
                      title: 'Action',
                      key: 'action',
                      align: 'center',
                      render: (data) => {
                          // console.log("selected data",data)
                              return (
                                  <span>
                                      <Link to={{pathname:"/edit_slots",
                                          state:{roomdata:data}
                                      }}>
                                        <Button type="primary" style={{ cursor: "pointer",marginRight:"50px" }} onClick={()=>{this.showModal(data)}}>Manage Slots</Button>
                                          </Link>
                                          <Link to={{pathname:"/viewslots/"+data.roomId,
                                          state:{roomdata:data},
                                         
                                      }} 
                                      >
                                        <Button type="primary" style={{ cursor: "pointer",marginRight:"10px" }} onClick={()=>{this.showModal(data)}}> View Slots</Button>
                                          </Link>
                                          {/* <Link to={{pathname:"/viewslots",
                                         state:{roomid:data}
                                      }}>
                                           <Button type="primary" style={{ cursor: "pointer",marginRight:"10px" }} onClick={()=>{this.showModal(data)}}>Manage Slots</Button>
                                          </Link> */}
                                      {/* <Link to={{pathname:"/edit_room",
                                          state:{roomdata:data}
                                      }}>
                                          <Button type="primary" icon="edit" style={{ cursor: "pointer",marginRight:"10px" }}></Button>
                                      </Link> */}
                                  </span>
                              )
                          }
                      
              }
  
          ];
        return(
            <App header="Rooms List">
               <Row gutter={16}>
                <Col xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:23}}>
                        <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                            <Breadcrumb.Item>Manage Rooms</Breadcrumb.Item>
                        </Breadcrumb>
                       
                    </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}}>
                            <Link to={{pathname:"/allrooms"}}
                            >
                                <Button type="default" style={{ marginBottom: 16, float: "right", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                                    Create Slots For All Rooms
                                </Button>
                            </Link>
                    </Col>
                </Row>
       
                <Table  columns={columns} loading={this.state.loading} dataSource={this.state.merchantData} />
            </App>
        )
    }
}
export default withApollo(Roomlist) 